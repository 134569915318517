import initialState from "store/initialState";
import {
  CREATE_ENROLLMENT,
  GET_ENROLLMENT,
  ADD_YEAR,
  REMOVE_YEAR,
  FLIP_STUDY_PERIOD,
  CHANGE_MMS_ENROLLMENT,
  ASSIGN_SUBJECT,
  REMOVE_SUBJECT,
  FLIP_SELF_EVAL_SELECTION,
  AUTO_ASSIGN_CORE_SUBJECT,
  CHANGE_TEMPLATE_STATE,
  LOAD_TEMPLATE,
  RENAME_PLAN,
  CHANGE_SUBJECT_TYPE_ASSIGNMENT,
  CHANGE_SECONDARY_COURSES_ENROLLMENT,
  ADD_TERM_OVERLOAD,
  FLIP_SHOW_PLACEHOLDERS,
} from "actions/enrollment";
import { failed, fulfilled, pending } from "lib/promiseMiddlewareTypes";
import { CHANGE_COURSE, CHANGE_YEAR } from "actions/planCreationConfig";
import { GET_SIMILAR_SUBJECTS } from "actions/similarSubjectsSearch";
import {
  ADD_ELIGIBILITY,
  ADMIN_AUTH,
  CLONE_TEMPLATE_BY_YEAR,
  GET_ALL_ELIGIBILITY,
  GET_TEMPLATE_DEFINITIONS,
  REMOVE_TEMPLATE,
} from "actions/admin";
import { START_SUBMITTING, STOP_SUBMITTING } from "actions/submittingChanges";

const submittingChanges = (state = initialState.submittingChanges, action: any) => {
  switch (action.type) {
    case START_SUBMITTING:
    case pending(CREATE_ENROLLMENT):
    case pending(GET_ENROLLMENT):
    case pending(ADD_YEAR):
    case pending(REMOVE_YEAR):
    case pending(CHANGE_COURSE):
    case pending(CHANGE_YEAR):
    case pending(FLIP_STUDY_PERIOD):
    case pending(FLIP_SELF_EVAL_SELECTION):
    case pending(CHANGE_MMS_ENROLLMENT):
    case pending(ASSIGN_SUBJECT):
    case pending(REMOVE_SUBJECT):
    case pending(GET_SIMILAR_SUBJECTS):
    case pending(AUTO_ASSIGN_CORE_SUBJECT):
    case pending(ADMIN_AUTH):
    case pending(CHANGE_TEMPLATE_STATE):
    case pending(LOAD_TEMPLATE):
    case pending(GET_TEMPLATE_DEFINITIONS):
    case pending(REMOVE_TEMPLATE):
    case pending(RENAME_PLAN):
    case pending(CLONE_TEMPLATE_BY_YEAR):
    case pending(CHANGE_SUBJECT_TYPE_ASSIGNMENT):
    case pending(GET_ALL_ELIGIBILITY):
    case pending(ADD_ELIGIBILITY):
    case pending(CHANGE_SECONDARY_COURSES_ENROLLMENT):
    case pending(ADD_TERM_OVERLOAD):
    case pending(FLIP_SHOW_PLACEHOLDERS):
      return true;
    case STOP_SUBMITTING:
    case failed(CREATE_ENROLLMENT):
    case fulfilled(CREATE_ENROLLMENT):
    case failed(GET_ENROLLMENT):
    case fulfilled(GET_ENROLLMENT):
    case failed(ADD_YEAR):
    case fulfilled(ADD_YEAR):
    case failed(REMOVE_YEAR):
    case fulfilled(REMOVE_YEAR):
    case failed(CHANGE_COURSE):
    case fulfilled(CHANGE_COURSE):
    case failed(CHANGE_YEAR):
    case fulfilled(CHANGE_YEAR):
    case failed(FLIP_STUDY_PERIOD):
    case fulfilled(FLIP_STUDY_PERIOD):
    case failed(FLIP_SELF_EVAL_SELECTION):
    case fulfilled(FLIP_SELF_EVAL_SELECTION):
    case failed(CHANGE_MMS_ENROLLMENT):
    case fulfilled(CHANGE_MMS_ENROLLMENT):
    case failed(ASSIGN_SUBJECT):
    case fulfilled(ASSIGN_SUBJECT):
    case failed(REMOVE_SUBJECT):
    case fulfilled(REMOVE_SUBJECT):
    case failed(GET_SIMILAR_SUBJECTS):
    case fulfilled(GET_SIMILAR_SUBJECTS):
    case failed(AUTO_ASSIGN_CORE_SUBJECT):
    case fulfilled(AUTO_ASSIGN_CORE_SUBJECT):
    case failed(ADMIN_AUTH):
    case fulfilled(ADMIN_AUTH):
    case failed(CHANGE_TEMPLATE_STATE):
    case fulfilled(CHANGE_TEMPLATE_STATE):
    case failed(LOAD_TEMPLATE):
    case fulfilled(LOAD_TEMPLATE):
    case fulfilled(GET_TEMPLATE_DEFINITIONS):
    case failed(GET_TEMPLATE_DEFINITIONS):
    case fulfilled(REMOVE_TEMPLATE):
    case failed(REMOVE_TEMPLATE):
    case fulfilled(RENAME_PLAN):
    case failed(RENAME_PLAN):
    case fulfilled(CLONE_TEMPLATE_BY_YEAR):
    case failed(CLONE_TEMPLATE_BY_YEAR):
    case fulfilled(CHANGE_SUBJECT_TYPE_ASSIGNMENT):
    case failed(CHANGE_SUBJECT_TYPE_ASSIGNMENT):
    case fulfilled(GET_ALL_ELIGIBILITY):
    case failed(GET_ALL_ELIGIBILITY):
    case fulfilled(ADD_ELIGIBILITY):
    case failed(ADD_ELIGIBILITY):
    case fulfilled(CHANGE_SECONDARY_COURSES_ENROLLMENT):
    case failed(CHANGE_SECONDARY_COURSES_ENROLLMENT):
    case fulfilled(ADD_TERM_OVERLOAD):
    case failed(ADD_TERM_OVERLOAD):
    case fulfilled(FLIP_SHOW_PLACEHOLDERS):
    case failed(FLIP_SHOW_PLACEHOLDERS):
      return false;
  }
  return state;
};

export default submittingChanges;
