/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState, ITemplateSummary, TemplateStage } from "store/types";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { ResponsiveCenter } from "components/spinner/Center";
import { fromPairs, isEmpty, orderBy } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ForwardIcon from "@mui/icons-material/Forward";

import {
  Button,
  Checkbox,
  IconButton,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
  useTheme,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {
  NoFilter,
  DefaultColumnFilterDefinition,
  FilterTypes,
  SelectColumnFilter,
  stopPropagationHandler,
} from "./table";
import { AdminAppHeader } from "containers/app-bar/AdminAppBar";
import { Link } from "@reach/router";
import { timeAgo } from "lib/date";
import { Aligned } from "components/aligned/alignedDiv";
import { getTemplateSummaries } from "actions/admin";
import { templateName } from "lib/templates";
import styled from "styled-components";
import { changeTemplateState } from "actions/enrollment";
import { ChangeNameDialog } from "../planAction/ChangeNameDialog";
import { DeleteTemplateDialog } from "../planAction/DeleteTemplateDialog";
import { CloneTemplateDialog } from "../planAction/CloneTemplateDialog";
import { EditNotesDialog } from "../planAction/EditNotesDialog";
import { featureToggles } from "../../config/featureToggles";
import htmlToDraft from "html-to-draftjs";
import { ContentState } from "draft-js";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { brandBlue } from "theme/colors";

const DontBreak = styled.span`
  display: inline-block;
`;

const NoteContent = styled.div`
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  max-height: 50px;
  white-space: nowrap;
`;

interface IProps {
  path?: string;
  id?: string;
  children?: any | null;
}

const useTemplatesStyle = (theme: Theme) => ({
  table: {
    marginTop: "2rem",
  },
  header: {
    "& strong": {
      fontSize: "110%",
    },
    whiteSpace: "nowrap",
  },
  span: {
    fontSize: "14px",
  },
  navButton: {
    color: theme.palette.common.black,
    marginTop: "24px",
  },
  navIcon: {
    fill: theme.palette.common.black,
  },
  templateButton: {
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
  },
});

export const Templates = (_props: IProps) => {
  const admin = useSelector((state: IState) => state.admin);
  const coursesHash = useMemo(() => fromPairs((admin?.courses ?? []).map((c) => [c.recordId, c])), [admin?.courses]);
  const componentHash = useMemo(
    () => fromPairs((admin?.components ?? []).map((c) => [c.recordId, c])),
    [admin?.components],
  );
  const theme = useTheme();
  const tableStyles = useTemplatesStyle(theme);

  const templates = useMemo(() => admin?.templateSummaries ?? [], [admin?.templateSummaries]);
  const data = useMemo(
    () => orderBy(templates ?? [], ["lastUpdatedTS", "year", "name"], ["desc", "desc", "asc"]),
    [templates],
  );
  const noTemplates = data.length === 0;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTemplateSummaries());
  }, [dispatch]);

  const onTemplateStateChanged = useCallback(
    (templateId: string, state: TemplateStage) => {
      dispatch(changeTemplateState(state, templateId));
    },
    [dispatch],
  );

  const [renamedPlanId, setRenamedPlanId] = useState<string | null>(null);
  const closeNameDialog = useCallback(() => setRenamedPlanId(null), []);
  const openNameDialog = useCallback((id: string) => setRenamedPlanId(id), []);

  const [editNotesPlanId, setEditNotesPlanId] = useState<string | null>(null);
  const closeEditNotesDialog = useCallback(() => setEditNotesPlanId(null), []);
  const openEditNotesDialog = useCallback((id: string) => setEditNotesPlanId(id), []);

  const [deletePlanId, setDeletePlanId] = useState<string | null>(null);
  const closeDeleteDialog = useCallback(() => setDeletePlanId(null), []);
  const openDeleteDialog = useCallback((id: string) => setDeletePlanId(id), []);

  const [clonePlanId, setClonePlanId] = useState<string[]>([]);
  const closeCloneDialog = useCallback((flag: boolean) => {
    if (flag) {
      setCheckedRows([]);
    }
    setClonePlanId([]);
  }, []);
  const openCloneDialog = useCallback((ids: string[]) => setClonePlanId(ids), []);

  let columns: any[] = useMemo(
    () => [
      {
        Header: "Course",
        accessor: (row: ITemplateSummary) => {
          const course = coursesHash[row.courseRecordId];
          return course ? `${course.name} (${course.code})` : "";
        },
        sortable: true,
        filter: "includesIgnoreCase",
        minWidth: 150,
        Cell: (cellProps: any) => {
          const row = cellProps.row.original;
          const course = coursesHash[row.courseRecordId];
          if (!course) {
            return "";
          }
          return (
            <>
              {course.name} <DontBreak>({course.code})</DontBreak>{" "}
            </>
          );
        },
      },
      {
        Header: "Name",
        accessor: (row: ITemplateSummary) => templateName(row, componentHash),
        sortable: true,
        minWidth: 200,
        filter: "includesIgnoreCase",
        Cell: ({ value, row }: any) => (
          <Aligned style={{ alignItems: "start" }}>
            <div>{value}</div>
            &nbsp; &nbsp;
            <Tooltip arrow title="Rename Template" aria-label="Rename Template" placement="top">
              <IconButton onClick={(_e: any) => openNameDialog(row.original.id)} size="small">
                <BorderColorIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Aligned>
        ),
      },
      {
        Header: "Notes",
        accessor: "notes",
        sortable: true,
        minWidth: 200,
        filter: "includesIgnoreCase",
        Cell: ({ value, row }: any) => {
          let result = "";
          if (value) {
            const contentBlock = htmlToDraft(value);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            result = contentState.getPlainText();
          }

          return (
            <Aligned style={{ alignItems: "start" }}>
              <NoteContent>{result}</NoteContent>
              &nbsp; &nbsp;
              <Tooltip arrow title="Add Template Notes" aria-label="Add Template Notes" placement="top">
                <IconButton onClick={(_e: any) => openEditNotesDialog(row.original.id)} size="small">
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Aligned>
          );
        },
      },
      {
        Header: "Year",
        accessor: "year",
        sortable: true,
        Filter: SelectColumnFilter,
        width: 100,
      },
      {
        Header: "Entry",
        accessor: "isMidyearEntry",
        sortable: true,
        useCellInFilter: true,
        Filter: SelectColumnFilter,
        width: 130,
        Cell: ({ value }: any) => (value ? "Mid Year" : "Start Year"),
      },
      {
        Header: "State",
        accessor: "template.state",
        sortable: true,
        Filter: SelectColumnFilter,
        width: 140,
        Cell: ({ value, row }: any) => (
          <Select
            variant={"standard"}
            value={value}
            native
            onChange={(e) => onTemplateStateChanged(row.original.id, e.target.value as any)}
          >
            {Object.values(TemplateStage).map((state) => (
              <option value={state} key={state}>
                {state}
              </option>
            ))}
          </Select>
        ),
      },
      {
        Header: "Last Updated",
        accessor: "lastUpdatedTS",
        sortable: true,
        Filter: NoFilter,
        minWidth: 100,
        maxWidth: 200,
        Cell: ({ value }: any) => value && `${timeAgo(value)} ago`,
      },
      {
        Header: "",
        accessor: "id",
        sortable: false,
        Filter: NoFilter,
        width: 55,
        Cell: ({ value }: any) => (
          <Aligned>
            <Tooltip arrow title="Edit Template" aria-label="Edit Template" placement="top">
              <Link to={`/staff/template/${value}`}>
                <IconButton size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>

            <Tooltip arrow title="Delete Template" aria-label="Delete Template" placement="top">
              <IconButton size="small" onClick={(_e: any) => openDeleteDialog(value)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Copy Template" aria-label="Copy Template" placement="top">
              <IconButton size="small" onClick={(_e: any) => openCloneDialog([value])}>
                <ForwardIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Aligned>
        ),
      },
    ],
    [
      componentHash,
      coursesHash,
      onTemplateStateChanged,
      openCloneDialog,
      openDeleteDialog,
      openEditNotesDialog,
      openNameDialog,
    ],
  );

  const columnsOld: any[] = useMemo(
    () => [
      {
        Header: "Course",
        accessor: (row: ITemplateSummary) => {
          const course = coursesHash[row.courseRecordId];
          return course ? `${course.name} (${course.code})` : "";
        },
        sortable: true,
        filter: "includesIgnoreCase",
        minWidth: 150,
        Cell: (cellProps: any) => {
          const row = cellProps.row.original;
          const course = coursesHash[row.courseRecordId];
          if (!course) {
            return "";
          }
          return (
            <>
              {course.name} <DontBreak>({course.code})</DontBreak>{" "}
            </>
          );
        },
      },
      {
        Header: "Name",
        accessor: (row: ITemplateSummary) => templateName(row, componentHash),
        sortable: true,
        minWidth: 200,
        filter: "includesIgnoreCase",
        Cell: ({ value, row }: any) => (
          <Aligned style={{ alignItems: "start" }}>
            <div>{value}</div>
            &nbsp; &nbsp;
            <Tooltip arrow title="Rename Template" aria-label="Rename Template" placement="top">
              <IconButton onClick={(_e: any) => openNameDialog(row.original.id)} size="small">
                <BorderColorIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Aligned>
        ),
      },
      {
        Header: "Year",
        accessor: "year",
        sortable: true,
        Filter: SelectColumnFilter,
        width: 100,
      },
      {
        Header: "Entry",
        accessor: "isMidyearEntry",
        sortable: true,
        useCellInFilter: true,
        Filter: SelectColumnFilter,
        width: 130,
        Cell: ({ value }: any) => (value ? "Mid Year" : "Start Year"),
      },
      {
        Header: "State",
        accessor: "template.state",
        sortable: true,
        Filter: SelectColumnFilter,
        width: 140,
        Cell: ({ value, row }: any) => (
          <Select
            variant="standard"
            value={value}
            native
            onChange={(e) => onTemplateStateChanged(row.original.id, e.target.value as any)}
          >
            {Object.values(TemplateStage).map((state) => (
              <option value={state} key={state}>
                {state}
              </option>
            ))}
          </Select>
        ),
      },
      {
        Header: "Last Updated",
        accessor: "lastUpdatedTS",
        sortable: true,
        Filter: NoFilter,
        minWidth: 100,
        maxWidth: 200,
        Cell: ({ value }: any) => value && `${timeAgo(value)} ago`,
      },
      {
        Header: "",
        accessor: "id",
        sortable: false,
        Filter: NoFilter,
        width: 55,
        Cell: ({ value }: any) => (
          <Aligned>
            <Tooltip arrow title="Edit Template" aria-label="Edit Template" placement="top">
              <Link to={`/staff/template/${value}`}>
                <IconButton size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>

            <Tooltip arrow title="Delete Template" aria-label="Delete Template" placement="top">
              <IconButton size="small" onClick={(_e: any) => openDeleteDialog(value)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Copy Template" aria-label="Copy Template" placement="top">
              <IconButton size="small" onClick={(_e: any) => openCloneDialog(value)}>
                <ForwardIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Aligned>
        ),
      },
    ],
    [componentHash, coursesHash, onTemplateStateChanged, openCloneDialog, openDeleteDialog, openNameDialog],
  );

  columns = featureToggles.editNotes ? columns : columnsOld;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Instead of using 'rows', we'll use page,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilterDefinition,
      initialState: { pageIndex: 0, pageSize: 20 },
      filterTypes: FilterTypes,
    } as any,
    useFilters,
    useSortBy,
    usePagination,
  ) as any;

  const handleChangePage = useCallback((_e: any, newPage: number) => gotoPage(newPage), [gotoPage]);
  const handleChangeRowsPerPage = useCallback((e: any) => setPageSize(Number(e.target.value)), [setPageSize]);

  // *** MULTI-SELECT ***
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const isChecked = (id: string) => checkedRows.includes(id);
  const onCheckToggle = useCallback(
    (id: string) => {
      const list = Object.assign([], checkedRows);
      if (id === "ALL") {
        const idx = list.indexOf("ALL");
        if (idx === -1) {
          list.splice(0);
          !isEmpty(page) &&
            page.forEach((row: any) => {
              list.push(row.id);
            });
          list.push("ALL");
        } else {
          list.splice(0);
        }
      } else {
        const idx = list.indexOf(id);
        if (idx === -1) {
          list.push(id);
        } else {
          list.splice(idx, 1);
          const i = list.indexOf("ALL");
          if (i >= 0) list.splice(i, 1);
        }
      }
      setCheckedRows(list);
    },
    [checkedRows, page, setCheckedRows],
  );

  const onCancel = useCallback(() => {
    setCheckedRows([]);
  }, []);

  const onCopyTemplates = useCallback(() => {
    const planIds: string[] = [];
    checkedRows.forEach((row) => {
      if (row !== "ALL") {
        const pageRow = page.find((r: any) => r.index === Number(row));
        if (pageRow) {
          planIds.push(pageRow.original.id);
        }
      }
    });
    openCloneDialog(planIds);
  }, [checkedRows, openCloneDialog, page]);
  if (!admin) {
    return null;
  }
  return (
    <>
      <AdminAppHeader path="templates" />
      <ResponsiveCenter>
        {featureToggles.componentEligibility && (
          <Aligned style={{ justifyContent: "right" }}>
            <Link to="/staff/new-template">
              <Button
                sx={tableStyles.navButton}
                variant="text"
                startIcon={<AddOutlined sx={tableStyles.navIcon} fontSize="small" />}
              >
                Add&nbsp;Template
              </Button>
            </Link>
          </Aligned>
        )}
        {noTemplates ? (
          <p>No templates are available.</p>
        ) : (
          <>
            {checkedRows.length > 0 && (
              <div
                style={{
                  backgroundColor: brandBlue.toString(),
                  width: "100%",
                  color: "white",
                  borderRadius: "5px",
                  marginTop: "24px",
                }}
              >
                <Aligned style={{ padding: "7px 16px 7px 16px" }}>
                  {checkedRows.filter((r) => r !== "ALL").length} items selected
                  <div style={{ marginLeft: "auto" }}>
                    <Button sx={tableStyles.templateButton} variant="text" onClick={() => onCopyTemplates()}>
                      Copy Templates
                    </Button>
                    &nbsp; |
                    <Button sx={tableStyles.templateButton} variant="text" onClick={() => onCancel()}>
                      Cancel
                    </Button>
                  </div>
                </Aligned>
              </div>
            )}

            <Table {...getTableProps()} sx={tableStyles.table} size="small">
              <colgroup>
                <col />
                {columns.map((c, i) => (
                  <col
                    key={i}
                    width={c.width ?? "auto"}
                    style={{
                      minWidth: c.minWidth,
                      maxWidth: c.maxWidth,
                    }}
                  />
                ))}
              </colgroup>
              <TableHead>
                {headerGroups.map((headerGroup: any, headerGroupIdx: number) => (
                  <TableRow
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id ?? headerGroupIdx}
                    sx={tableStyles.header}
                  >
                    <TableCell>
                      <strong>&nbsp;</strong>
                      <div style={{ height: "0px" }}></div>
                      <Checkbox checked={isChecked("ALL")} onChange={() => onCheckToggle("ALL")} />
                    </TableCell>
                    {headerGroup.headers.map((column: any, thIdx: number) => {
                      return (
                        <TableCell
                          {...column.getHeaderProps(column.sortable ? column.getSortByToggleProps() : undefined)}
                          key={column.id ?? thIdx}
                        >
                          <strong>{column.render("Header")}</strong>
                          {column.sortable && (
                            <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? "desc" : "asc"} />
                          )}
                          {/* Render the columns filter UI */}
                          <div onClick={stopPropagationHandler}>
                            {(column as any).canFilter ? column.render("Filter") : null}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {(page as any[]).map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} key={row.id ?? rowIndex}>
                      <TableCell>
                        <Checkbox checked={isChecked(row.id)} onChange={() => onCheckToggle(row.id)} />
                      </TableCell>
                      {row.cells.map((cell: any, cellIdx: number) => {
                        return (
                          <TableCell {...cell.getCellProps()} key={cellIdx}>
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </>
        )}
      </ResponsiveCenter>
      <ChangeNameDialog
        open={!!renamedPlanId}
        close={closeNameDialog}
        plan={templates.find((t) => t.id === renamedPlanId)}
        isTemplate
      />
      <EditNotesDialog
        open={!!editNotesPlanId}
        close={closeEditNotesDialog}
        plan={templates.find((t) => t.id === editNotesPlanId)}
        isTemplate
      />
      <DeleteTemplateDialog
        open={!!deletePlanId}
        close={closeDeleteDialog}
        templateSummary={templates.find((t) => t.id === deletePlanId)}
      />
      {clonePlanId.length > 0 && (
        <CloneTemplateDialog
          open={clonePlanId.length > 0}
          close={closeCloneDialog}
          templateSummary={templates.filter((t) => clonePlanId.includes(t.id))}
        />
      )}
      {/* <Link to="/staff/new-template">
        <Fab color="primary" className={classes.fab} variant="extended">
          <AddOutlined className={classes.fabIcon} />
          Add Template
        </Fab>
      </Link> */}
    </>
  );
};
