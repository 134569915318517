import AppFooter from "components/app-footer/app-footer";
import AppBar from "containers/app-bar/AppBar";
import styled from "styled-components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ResponsiveCenter } from "components/spinner/Center";
import { Aligned } from "components/aligned/alignedDiv";
import { Button } from "@mui/material";
import { useMenuState } from "@szhsin/react-menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountActionMenu } from "./AccountActionMenu";
import { PlanList } from "./PlanList";
import { get } from "lib/fetch";
import { clearProfile, setProfile } from "actions/profile";
import { useDispatch } from "react-redux";
import { useNavigate } from "@reach/router";
import Spinner from "components/spinner";
import { Hint } from "containers/Hint";
import { HintToolTip } from "store/types";

interface IProps {
  path?: string;
}

const Background = styled.div`
  background-color: #012a58;
  width: 100%;
`;

const H3 = styled.h3`
  color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0rem;
`;

const useStyles = () => ({
  menuIcon: {
    fill: "white",
  },
  clearAllButton: {
    marginTop: "10px",
    marginBottom: "10px",
    textTransform: "none",
  },
});

export const MyAccount = (_props: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const [anchorElRef, setAnchorElRef] = useState<React.Ref<any>>(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const [isLoading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  const [menuAnchorPoint, setMenuAnchorPoint] = useState({ x: 0, y: 0 });
  const styles = useStyles();

  const toggleRefresh = useCallback(() => setRefresh(!refresh), [refresh]);

  useEffect(() => {
    const isLoggedIn = async () => {
      try {
        setLoading(true);
        const resp = await get("/v1/profile");
        if (resp) {
          dispatch(setProfile(resp));
        }
      } catch (err) {
        dispatch(clearProfile());
        navigate("/");
      } finally {
        setLoading(false);
      }
    };
    isLoggedIn();
  }, [dispatch, navigate]);

  const openActionMenu = useCallback(
    (event: any) => {
      setMenuAnchorPoint({ x: event.clientX, y: event.clientY });
      setAnchorElRef(ref);
      openMenu();
    },
    [ref, setAnchorElRef, openMenu],
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar openTemplatesDrawer={undefined} mmsRecordIds={[]} />
      <Background className="app-subheading">
        <ResponsiveCenter>
          <Aligned style={{ justifyContent: "row" }}>
            <div style={{ minHeight: "126px", display: "flex", alignItems: "center" }}>
              <Row>
                <H3>My Course Plans</H3>
                &nbsp;&nbsp;
                <Hint
                  hintType={HintToolTip.ACCOUNT_CREATE_PLAN}
                  hideHint={menuProps.state === "open"}
                  placement="right"
                >
                  <Button
                    className="do-not-print"
                    ref={ref}
                    aria-label="Course Plan menu"
                    id="plan-action-menu-btn"
                    onClick={openActionMenu}
                    variant="contained"
                    color="primary"
                    sx={styles.clearAllButton}
                    endIcon={<MoreVertIcon style={{ fill: "white" }} />}
                  >
                    Actions
                  </Button>
                </Hint>
                <AccountActionMenu
                  anchorRef={anchorElRef}
                  anchorPoint={menuAnchorPoint}
                  closeMenu={closeMenu}
                  refresh={() => toggleRefresh()}
                  {...menuProps}
                />
              </Row>
            </div>
          </Aligned>
        </ResponsiveCenter>
      </Background>
      <br />
      <div style={{ flex: 1 }}>
        <Spinner loading={isLoading} />
        {!isLoading && <PlanList refresh={refresh} />}
      </div>
      <AppFooter />
    </div>
  );
};
