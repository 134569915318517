import React, { useCallback, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "@reach/router";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Aligned } from "components/aligned/alignedDiv";
import { FormHeading } from "components/forms";
import { IState } from "store/types";
import { useSelector } from "react-redux";

interface IProps {
  path?: string;
}

const H1 = styled.h1`
  text-align: center;
  font-size: xxx-large;
`;

const OuterGrid = styled(Grid)`
  padding: 2.5vh 1rem 1rem 1rem;
  background-color: #f7f7f7;
  justify-content: center;
`;

export const AccessMyCoursePlanner = (_props: IProps) => {
  const profile = useSelector((state: IState) => state.profile);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile && profile.authenticated) {
      navigate("/account");
    }
  }, [navigate, profile]);

  const handleLogin = useCallback(() => {
    window.location.href = "/apis/v1/login";
  }, []);

  const handleContinueAsGuest = useCallback(() => {
    navigate("/new");
  }, [navigate]);

  return (
    <>
      <OuterGrid container spacing={0} alignItems="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <H1>Access My Course Planner</H1>
          <Aligned style={{ justifyContent: "center" }}>
            <PlayCircleOutlineIcon fontSize="small" />
            &nbsp;
            <span>
              First time using My Course Planner? Please watch our{" "}
              <a
                href="https://students.unimelb.edu.au/your-course/manage-your-course/planning-your-course-and-subjects/faculty-course-planning-resources/my-course-planner"
                target="_"
              >
                {/* <YouTubeIcon fontSize="small" /> */}
                introductory video
              </a>
              .
            </span>
          </Aligned>
          <br />
          <FormHeading style={{ textAlign: "center" }}>
            Login with your University of Melbourne account or continue as a guest.
          </FormHeading>
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{
                minHeight: "200px",
                display: "flex",
                flexDirection: "column",
                paddingRight: "16px",
                height: "100%",
              }}
            >
              <div style={{ padding: 0, flexGrow: 1 }}>
                Unlock additional features of the tool by logging in with your University of Melbourne account including
                saving new course plans and importing previous plans.
              </div>
              <div style={{ marginTop: "auto", display: "flex", justifyContent: "flex-start" }}>
                <Button
                  className="do-not-print"
                  aria-label="login"
                  onClick={handleLogin}
                  variant="contained"
                  size="large"
                  endIcon={<ChevronRightIcon style={{ fill: "white" }} />}
                >
                  Login
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                minHeight: "200px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                paddingRight: "16px",
                borderLeft: "1px solid #575757",
              }}
            >
              <div style={{ padding: 0, flexGrow: 1 }}>
                Use My Course Planner as a guest to explore your study options and decide which subjects and major(s)
                minors and or specialisations are right for you.
              </div>
              <div style={{ marginTop: "auto", display: "flex", justifyContent: "flex-start" }}>
                <Button
                  className="do-not-print"
                  aria-label="continue as guest"
                  onClick={handleContinueAsGuest}
                  variant="contained"
                  size="large"
                  endIcon={<ChevronRightIcon style={{ fill: "white" }} />}
                >
                  Continue as guest
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </OuterGrid>
    </>
  );
};
