import React, { useCallback, useState } from "react";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { brandGrey } from "theme/colors";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as ImportPlanIcon } from "./PlanImportIcon.svg";
import { Icon, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "@reach/router";
import { ImportPlanDialog } from "./ImportPlanDialog";

interface IProps {
  closeMenu: () => any;
  anchorRef: React.Ref<any>;
  anchorPoint: any;
  refresh: () => any;
}

export const AccountActionMenu = (props: IProps) => {
  const { closeMenu, anchorRef, anchorPoint, refresh, ...menuProps } = props;
  const [showDialog, setShowDialog] = useState(0);
  const navigate = useNavigate();

  const createNewPlan = useCallback(() => {
    navigate("/new");
  }, [navigate]);

  const importPlan = useCallback(() => {
    setShowDialog(1);
  }, []);

  return (
    <>
      <ControlledMenu
        className="do-not-print"
        {...menuProps}
        anchorRef={anchorRef}
        anchorPoint={anchorPoint}
        onClose={closeMenu}
      >
        <MenuItem onClick={createNewPlan} key="create-plan-menu-item">
          <ListItemIcon style={{ color: brandGrey.toHexString() }}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Create New Plan" />
        </MenuItem>
        <MenuItem onClick={importPlan} key="import-plan-menu-item">
          <ListItemIcon style={{ color: brandGrey.toHexString() }}>
            <Icon component={ImportPlanIcon} />
          </ListItemIcon>
          <ListItemText primary="Import Existing Plan" />
        </MenuItem>
      </ControlledMenu>
      {showDialog === 1 && (
        <ImportPlanDialog open={showDialog === 1} refresh={() => refresh()} close={() => setShowDialog(0)} />
      )}
    </>
  );
};
