import {
  IPlan,
  ISubject,
  SubjectTag,
  IComponent,
  IRuleInvocation,
  IState,
  BreadthDisciplineScope,
  ComponentType,
  ISlot,
  EnrollmentStatus,
} from "store/types";
import flatten from "lodash/flatten";
import zipObject from "lodash/zipObject";
import uniq from "lodash/uniq";
import isArray from "lodash/isArray";
import store from "store";
import { findSlotBySubject } from "./plan";
import { featureToggles } from "config/featureToggles";
import { sortBy, without } from "lodash";
import { getAssignedCourses, getCoursesFor } from "./assignment";
import { isHiddenMMSById } from "./components";
import { useSelector } from "react-redux";
import { getAssignedCourseCodes } from "./assignment";

export const getAllSubjects = () => (state: IState) =>
  [...(state.enrollment?.subjects ?? []), ...(state.subjectSearch.results ?? [])];

export const useAllKnownSubjects = () =>
  useSelector((state: IState) => [...(state.enrollment?.subjects ?? []), ...(state.subjectSearch.results ?? [])]);

export const getSubjectRecordIds = (plan: IPlan) => {
  const slots = flatten(plan.studyPeriods.map((sp) => sp.slots));
  return slots.map((slot) => slot.subjectRecordId).filter(Boolean) as string[];
};

export const unsassignedSubjects = (plan: IPlan | undefined, subjects: ISubject[]) => {
  if (!plan) {
    return subjects;
  }
  const assigned = getSubjectRecordIds(plan);
  const assignedDictionary = zipObject(assigned, assigned);
  return subjects.filter((s) => !assignedDictionary[s.recordId]);
};

export const getSubject = (subjects: ISubject[] | null | undefined, subjectRecordId?: string | null) =>
  (subjects ?? []).find((s) => s.recordId === subjectRecordId) || null;

export const getSubjectAssignmentLabel = (tag: SubjectTag | undefined, component?: { name: string; type?: string }) => {
  if (!tag) {
    return "";
  }
  const type = !component?.type || component.type.match(/specialisation/i) ? "" : component?.type;
  const name = component?.name || "";
  const label = [name, type].filter(Boolean).join(", ");
  switch (tag) {
    case SubjectTag.ASSIGNED:
    case SubjectTag.ASSIGNED_2:
    case SubjectTag.SECONDARY_COURSE:
      return label;
    case SubjectTag.BREADTH:
      return "Breadth";
    case SubjectTag.DISCIPLINE:
      return "Discipline";
    case SubjectTag.HIDDEN_MMS:
      return component?.name ?? "";
    case SubjectTag.CAN_BE_DISCIPLINE:
      return "Discipline";
    case SubjectTag.CORE:
      return "Compulsory";
    case SubjectTag.NOT_ASSIGNABLE:
      return "🚫 Unavailable for course";
    case SubjectTag.ON_PLAN:
      return "";
    default:
      return "";
  }
};

export const getAvailableTerms = (subject?: ISubject | null) => {
  if (!subject) {
    return [];
  }

  const subjectAvailabilities = isArray(subject.availability) ? subject.availability : [subject.availability];
  return uniq(subjectAvailabilities.map((a) => a?.studyPeriod)).filter(Boolean);
};

export const isSubjectAssignable = (courseCode: undefined | string | string[], subject?: ISubject | null) => {
  const courseCodes = flatten([courseCode]).filter(Boolean) as string[];
  if (courseCodes.length === 0 || !subject) {
    return false;
  }
  const courseReferences = [...(subject?.availableAsDiscipline || []), ...(subject?.availableAsBreadth || [])];
  return !!courseReferences.find((cr) => courseCodes.indexOf(cr.code) >= 0);
};

export const analyseSubject = (s: ISubject) => {
  const state: IState = store.getState();
  const { enrollment, optimisticUpdate } = state;
  const courseCodes = getAssignedCourseCodes(enrollment);
  const { plan: currentPlan, course, validation, allowedComponents } = enrollment!;
  const plan = { ...currentPlan, ...optimisticUpdate?.plan };
  const courseCode = course.code;
  const invocations = validation.invocations;

  const canBeBreadth = !!(s?.availableAsBreadth || []).find((cr) => cr.code === courseCode);
  const canBeDiscipline = !!(s?.availableAsDiscipline || []).find((cr) => cr.code === courseCode);
  const assignableToPlan = canBeBreadth || canBeDiscipline;

  const slot = findSlotBySubject(s.recordId, plan.studyPeriods);
  const isInPlan = !!slot;
  const taggedAsDiscipline = !!(slot?.tags ?? []).find((t) => t.name === "isDiscipline");
  const isDiscipline = (canBeDiscipline && !canBeBreadth) || (canBeDiscipline && taggedAsDiscipline);
  const isBreadth = canBeBreadth && !isDiscipline;
  const isMMSAssigned = !!slot?.mmsRecordId;
  const isFullYearSubject = isYearLong(s);
  const isCrossCredit = (slot?.courseRecordIds ?? []).length > 1;
  const assignedToHiddenMMS = !!slot?.mmsRecordId && isHiddenMMSById(slot?.mmsRecordId, allowedComponents);

  // const isDiplomaAssigned

  const isCourseCore = invocations
    .filter((i: IRuleInvocation) => i.recordId === plan.courseRecordId)
    .flatMap((i) => i.coreSubjectRecordIds ?? [])
    .includes(s.recordId);
  const isMMSCore = invocations
    .filter((i: IRuleInvocation) => slot?.mmsRecordId && i.recordId === slot?.mmsRecordId)
    .flatMap((i) => i.coreSubjectRecordIds ?? [])
    .includes(s.recordId);

  let uiTag: SubjectTag = SubjectTag.DISCIPLINE;
  if (!isSubjectAssignable(courseCodes, s)) {
    uiTag = SubjectTag.NOT_ASSIGNABLE;
  } else if (slot && without(slot.courseRecordIds, course.recordId).length > 0) {
    uiTag = SubjectTag.SECONDARY_COURSE;
  } else if (assignedToHiddenMMS && (isMMSCore || isCourseCore)) {
    uiTag = SubjectTag.CORE;
  } else if (slot && slot.mmsRecordId) {
    uiTag = mmsAssignedTag(plan, slot.mmsRecordId, allowedComponents);
  } else if (isCourseCore) {
    uiTag = SubjectTag.CORE;
  } else if (isDiscipline) {
    uiTag = SubjectTag.DISCIPLINE;
  } else if (isBreadth) {
    uiTag = SubjectTag.BREADTH;
  }

  return {
    assignableToPlan,
    canBeBreadth,
    canBeDiscipline,
    isDiscipline,
    isBreadth,
    // isCourseCore,
    isInPlan,
    uiTag,
    isMMSAssigned,
    isFullYear: isFullYearSubject,
    isCrossCredit,
  };
};

export const analyseDrawerSubject = (s: ISubject, invocation: IRuleInvocation) => {
  const subjectMeta = analyseSubject(s);

  const state: IState = store.getState();
  const { enrollment } = state;
  const { plan, allowedComponents } = enrollment!;

  const mmsRecordId = (plan?.mmsRecordIds ?? []).find((id) => id === invocation?.recordId);
  let targetCourses = getCoursesFor(enrollment, invocation.recordId);
  if (!targetCourses.find((c) => isSubjectAssignable(c.code, s))) {
    const matchingCourses = getAssignedCourses(enrollment).filter((c) => isSubjectAssignable(c.code, s));
    if (matchingCourses.length > 0) {
      targetCourses = matchingCourses;
    }
  }
  const mms = mmsRecordId && allowedComponents.find((ac) => ac.recordId === mmsRecordId);
  const mmsSubjectCardTitle = mms ? `${mms.name}, ${mms.type}` : "";

  let checklistSubjectTag: SubjectTag = subjectMeta.uiTag;
  if (subjectMeta.isInPlan) {
    checklistSubjectTag = SubjectTag.ON_PLAN;
  } else {
    if (targetCourses.length === 1 && targetCourses[0].recordId !== plan.courseRecordId) {
      checklistSubjectTag = SubjectTag.SECONDARY_COURSE;
    } else if (mmsRecordId) {
      checklistSubjectTag = mmsAssignedTag(plan, mmsRecordId, allowedComponents);
    } else if (subjectMeta.canBeBreadth && !subjectMeta.canBeDiscipline) {
      checklistSubjectTag = SubjectTag.BREADTH;
    } else if (!subjectMeta.canBeBreadth && subjectMeta.canBeDiscipline) {
      checklistSubjectTag = SubjectTag.DISCIPLINE;
    } else if (
      subjectMeta.canBeBreadth &&
      subjectMeta.canBeDiscipline &&
      invocation.matchingDetails?.breadthDisciplineScope
    ) {
      switch (invocation.matchingDetails?.breadthDisciplineScope) {
        case BreadthDisciplineScope.Breadth:
          checklistSubjectTag = SubjectTag.BREADTH;
          break;
        case BreadthDisciplineScope.Discipline:
          checklistSubjectTag = SubjectTag.DISCIPLINE;
          break;
      }
    }
  }

  const subjectAreasTitle = s.areaOfStudy.map((aos) => aos.description).join(",");
  let checklistSubjectTitle = getSubjectAssignmentLabel(checklistSubjectTag) || subjectAreasTitle;

  if ([SubjectTag.ASSIGNED, SubjectTag.ASSIGNED_2].indexOf(checklistSubjectTag) >= 0) {
    checklistSubjectTitle = mmsSubjectCardTitle;
  } else if (checklistSubjectTag === SubjectTag.NOT_ASSIGNABLE) {
    checklistSubjectTitle = `🚫 ${subjectAreasTitle} (Unavailable for course)`;
  } else if (checklistSubjectTag === SubjectTag.ON_PLAN) {
    checklistSubjectTitle = `${checklistSubjectTitle} (Already in plan)`;
  } else if (checklistSubjectTag === SubjectTag.SECONDARY_COURSE) {
    checklistSubjectTitle = targetCourses[0].name;
  }

  return {
    ...subjectMeta,
    checklistSubjectTag,
    checklistSubjectTitle,
  };
};

export const getAllKnownSubjects = (s: IState) => [
  ...(s.enrollment?.subjects ?? []),
  ...(s.enrollment?.referredSubjects ?? []),
  ...(s.subjectSearch.results ?? []),
  ...(s.similarSubjectsSearch?.results ?? []),
];

export const isYearLong = (subject?: ISubject | null) =>
  !!subject &&
  !!(subject.availability ?? []).find(
    (sa) => ["Year Long", "Year Long (Extended)", "Full Year"].indexOf(sa.studyPeriod) >= 0,
  );

const mmsDisplayIndex = (c?: IComponent) => {
  switch (c?.type) {
    case ComponentType.MAJOR:
      return 0;
    case ComponentType.MINOR:
      return 1;
    case ComponentType.INFORMAL_SPEC:
      return 2;
    default:
      return 3;
  }
};

const mmsAssignedTag = (plan: IPlan, mmsRecordId: string, allowedComponents: IComponent[]) => {
  if (isHiddenMMSById(mmsRecordId, allowedComponents)) {
    return SubjectTag.HIDDEN_MMS;
  }
  if (!featureToggles.secondaryMMSColour) {
    return SubjectTag.ASSIGNED;
  }
  // Majors before minors ...
  const orderedMMSRecordIds = sortBy(
    plan.mmsRecordIds.filter((ri) => !isHiddenMMSById(ri, allowedComponents)),
    (mmsId) => mmsDisplayIndex(allowedComponents.find((ac) => ac.recordId === mmsId)),
  );
  const mmsIndex = orderedMMSRecordIds.indexOf(mmsRecordId);
  return mmsIndex === 0 ? SubjectTag.ASSIGNED : SubjectTag.ASSIGNED_2;
};

export const getSubjectImportType = (slot: ISlot): string | null => {
  const state = store.getState() as IState;
  if (state.profile && state.profile.authenticated && state.profile.id === state.enrollment?.plan.userId) {
    for (const t of slot.tags) {
      if (t.name === EnrollmentStatus.Completed) return "Passed";
      else if (t.name === EnrollmentStatus.Enrolled) return "Enrolled";
    }
  }
  return null;
};
