import isString from "lodash/isString";
import isArray from "lodash/isArray";
import { IRuleInvocation, IRuleInvocationGroup } from "store/types";
import { isGroupEmpty, isGroupSuccessful } from "./invocations";

export const isBlank = (v: string | null | undefined) => {
  const value = isString(v) ? v.trim() : v;
  return !value;
};

export const validateValuePresence = (errors: any, values: any, errorKey: string, label = "Required") => {
  const value = values[errorKey];
  if (isBlank(value) || (isArray(value) && value.length === 0)) {
    errors[errorKey] = label;
  }
};

export const validateNumericValuePresence = (errors: any, values: any, errorKey: string, label = "Required") => {
  const value = values[errorKey];
  if (isBlank(value) || value.length === 0) {
    errors[errorKey] = label;
  }
};

export const recursiveRootGroupResult = (
  groups: IRuleInvocationGroup[],
  invocations: IRuleInvocation[],
  id: string | null,
): any => {
  const group = groups.find((g: IRuleInvocationGroup) => g.id === id);
  const groupParentId = group?.parentId || null;
  // Return result if top level group (i.e. parentId is null)
  if (!groupParentId) {
    return group?.result ?? (group && isGroupSuccessful(group, groups, invocations));
  }
  return recursiveRootGroupResult(groups, invocations, group?.parentId || null);
};

/** Only count subject errors if they are causing root level RuleGroup to fail */
export const subjectHasErrors = (
  invocations: IRuleInvocation[],
  groups: IRuleInvocationGroup[],
  subjectRecordId: string,
  section?: string,
) => {
  const failedInvocationsForSubject = invocations
    .filter((i: IRuleInvocation) => {
      if (section) {
        return i.recordId === subjectRecordId && !i.result && i.section === section;
      }
      return i.recordId === subjectRecordId && !i.result;
    })
    .filter((i: IRuleInvocation) => {
      if (i.groupId) {
        return !recursiveRootGroupResult(groups, invocations, i.groupId);
      }
      return !i.result;
    });
  return failedInvocationsForSubject.length > 0;
};

export const planChecklistHasFailedGroups = (planInvocations: IRuleInvocation[], allGroups: IRuleInvocationGroup[]) => {
  const rootGroups = allGroups.filter(
    (g: IRuleInvocationGroup) => !g.parentId && !isGroupEmpty(g, allGroups, planInvocations),
  );
  const failedPlanChecklistRootGroups = rootGroups.filter(
    (g: IRuleInvocationGroup) => !isGroupSuccessful(g, allGroups, planInvocations),
  );
  return failedPlanChecklistRootGroups.length > 0;
};

export const subjectHasAvailabilityErrors = (invocations: IRuleInvocation[], subjectRecordId: string) => {
  const errors = {
    hasAvailableInOtherPeriod: false,
    hasNotAvailableInCurrentYear: false,
  };
  invocations
    .filter((i) => i.recordId === subjectRecordId && i.result === false)
    .forEach((invocation) => {
      if (invocation.ruleType === "Availability" && invocation.progress && invocation.progress.tags.length === 1) {
        switch (invocation.progress.tags[0].name) {
          case "AvailableInOtherTeachingPeriod":
            errors.hasAvailableInOtherPeriod = true;
            break;
          case "NotAvailableInCurrentYear":
            errors.hasNotAvailableInCurrentYear = true;
            break;
        }
      }
    });
  return errors;
};
