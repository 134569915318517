export const SET_PROFILE = "profile/SET_PROFILE";
export const CLEAR_PROFILE = "profile/CLEAR_PROFILE";
export const RESET_HINTS = "profile/RESET_HINTS";
export const CLOSE_HINT = "profile/CLOSE_HINT";

export const setProfile = (profile: any) => ({
  type: SET_PROFILE,
  payload: profile,
});

export const clearProfile = () => ({
  type: CLEAR_PROFILE,
  payload: null,
});

export const resetAccountHints = () => ({
  type: RESET_HINTS,
  payload: {},
});

export const closeCurrentAccountHint = () => ({
  type: CLOSE_HINT,
  payload: {},
});
