const LOCAL_STORAGE_ID = "UOM-CPT-WORKSPACE-ID";

export const getWorkspaceId = () => {
  const workspaceId = localStorage.getItem(LOCAL_STORAGE_ID);
  return workspaceId === null ? undefined : workspaceId;
};

export const setWorkspaceId = (workspaceId: string | undefined) => {
  if (workspaceId) {
    localStorage.setItem(LOCAL_STORAGE_ID, workspaceId);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_ID);
  }
};
