import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import {
  CREATE_ENROLLMENT,
  GET_ENROLLMENT,
  ADD_YEAR,
  REMOVE_YEAR,
  FLIP_STUDY_PERIOD,
  CHANGE_MMS_ENROLLMENT,
  ASSIGN_SUBJECT,
  REMOVE_SUBJECT,
  CHANGE_SUBJECT_TYPE_ASSIGNMENT,
  CLOSE_HINT,
  SET_SUBJECT_SEARCH_OPEN_STATE,
  RESET_HINTS,
  GET_REFERRED_TO_SUBJECTS,
  FLIP_SELF_EVAL_SELECTION,
  AUTO_ASSIGN_CORE_SUBJECT,
  CHANGE_TEMPLATE_STATE,
  LOAD_TEMPLATE,
  RENAME_PLAN,
  EDIT_NOTES,
  REMOVE_ENROLLMENT,
  CHANGE_SECONDARY_COURSES_ENROLLMENT,
  ADD_TERM_OVERLOAD,
  REMOVE_TERM_OVERLOAD,
  FLIP_SHOW_PLACEHOLDERS,
} from "actions/enrollment";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { IEnrollment } from "store/types";
import { closeHint, matchingHint, resetHints } from "lib/hints";
import { normaliseEnrollment } from "lib/subjectGrid";

const withHint = (e?: IEnrollment | null) => {
  if (!e) {
    return e;
  }
  return { ...e, currentHint: matchingHint(e) };
};

const enrollment = (state = initialState.enrollment, action: any) => {
  switch (action.type) {
    case fulfilled(CREATE_ENROLLMENT):
    case fulfilled(GET_ENROLLMENT):
    case fulfilled(ASSIGN_SUBJECT):
    case fulfilled(REMOVE_SUBJECT):
    case fulfilled(REMOVE_ENROLLMENT):
    case fulfilled(CHANGE_MMS_ENROLLMENT):
    case fulfilled(CHANGE_SECONDARY_COURSES_ENROLLMENT):
    case fulfilled(CHANGE_SUBJECT_TYPE_ASSIGNMENT):
    case fulfilled(FLIP_SELF_EVAL_SELECTION):
    case fulfilled(AUTO_ASSIGN_CORE_SUBJECT):
    case fulfilled(LOAD_TEMPLATE):
      // console.log(JSON.stringify(action.payload.validation, null, 2));
      return normaliseEnrollment(
        withHint({
          ...state,
          ...action.payload,
          allowedComponents: sortBy(action.payload.allowedComponents || state?.allowedComponents || [], "name"),
          subjects: action.payload.subjects || state?.subjects || [],
          referredSubjects: action.payload.referredSubjects || state?.referredSubjects || [],
        }),
      );
    case fulfilled(ADD_YEAR):
    case fulfilled(REMOVE_YEAR):
    case fulfilled(FLIP_STUDY_PERIOD):
    case fulfilled(FLIP_SHOW_PLACEHOLDERS):
      return normaliseEnrollment(withHint({ ...state, plan: action.payload } as IEnrollment));
    case fulfilled(RENAME_PLAN):
    case fulfilled(EDIT_NOTES):
    case fulfilled(CHANGE_TEMPLATE_STATE):
      return action.payload.id === state?.plan.id
        ? normaliseEnrollment(withHint({ ...state, plan: action.payload } as IEnrollment))
        : state;
    case CLOSE_HINT:
      closeHint(state?.currentHint);
      return withHint(state);
    case RESET_HINTS:
      resetHints();
      return withHint(state);
    case SET_SUBJECT_SEARCH_OPEN_STATE:
      return withHint({ ...state, subjectSearchOpen: action.payload.open } as IEnrollment);
    case fulfilled(GET_REFERRED_TO_SUBJECTS):
      const referredSubjects = [...action.payload, ...(state?.referredSubjects || [])];
      return {
        ...state,
        referredSubjects: sortBy(
          uniqBy(referredSubjects, (s) => s.recordId),
          (s) => s.code,
        ),
      };
    case fulfilled(ADD_TERM_OVERLOAD): {
      const { enrollment, period } = action.payload;
      const overloads = state?.overloads ?? [];
      const match = overloads.find((o: any) => String(o.year) === String(period.year) && o.type === period.type);
      const otherOverloads = overloads.filter(
        (o: any) => String(o.year) !== String(period.year) || o.type !== period.type,
      );
      return normaliseEnrollment(
        withHint({
          ...enrollment,
          overloads: [
            ...otherOverloads,
            {
              type: period.type,
              year: period.year,
              pointsOverload: (match?.pointsOverload ?? 0) + 12.5,
            },
          ],
        } as IEnrollment),
      );
    }
    case REMOVE_TERM_OVERLOAD: {
      const period = action.payload;
      const overloads = state?.overloads ?? [];
      const match = overloads.find((o: any) => String(o.year) === String(period.year) && o.type === period.type);
      const otherOverloads = overloads.filter(
        (o: any) => String(o.year) !== String(period.year) || o.type !== period.type,
      );
      return normaliseEnrollment(
        withHint({
          ...state,
          overloads: [
            ...otherOverloads,
            {
              type: period.type,
              year: period.year,
              pointsOverload: Math.max((match?.pointsOverload ?? 0) - 12.5, 0),
            },
          ],
        } as IEnrollment),
      );
    }
    default:
      return state;
  }
};

export default enrollment;
