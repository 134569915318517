export const lastUpdated = (timestamp: string) => {
  const currentDate = new Date();
  const dateTS = new Date(timestamp);
  const days = Math.round((currentDate.getTime() - dateTS.getTime()) / (24 * 60 * 60 * 1000));
  if (days < 1) return "Today";
  if (days < 7) return `${days} day${days > 1 ? "s" : ""} ago`;
  if (days < 30) return `${Math.floor(days / 7)} week${Math.floor(days / 7) > 1 ? "s" : ""} ago`;
  if (days < 365) return `${Math.floor(days / 30)} month${Math.floor(days / 30) > 1 ? "s" : ""} ago`;

  return `${Math.floor(days / 365)} year${Math.floor(days / 365) > 1 ? "s" : ""} ago`;
};

export const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  //const hours = String(date.getHours()).padStart(2, "0");
  //const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year}`;
};
