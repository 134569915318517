import { IState } from "./types";

const initialState: IState = {
  loading: false,
  submittingChanges: false,
  planCreationConfig: null,
  enrollment: null,
  snackBarState: null,
  subjectSearch: {
    searchInProgress: false,
    searchTimestamp: 0,
    query: {
      text: "",
      level: [],
      studyPeriod: [],
      courseRecordIds: [],
      areaOfStudy: [],
      onlyBreadth: false,
      onlyDiscipline: true,
      page: 0,
      pageSize: 20,
    },
    results: [],
    totalCount: 0,
  },
  optimisticUpdate: null,
  checklistDrawerForSubjectId: null,
  subjectDrawerForRule: null,
  similarSubjectsSearch: {
    searchInProgress: false,
    searchTimestamp: 0,
    subjectRecordId: null,
    results: [],
  },
  draggedSubjectId: null,
  searchDrawerOpen: false,
  admin: null,
  focusInvocationId: null,
  profile: null,
};

export default initialState;
