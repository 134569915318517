import {
  IPlan,
  ISlotIdentifier,
  IStudyPeriod,
  ISubject,
  Semester,
  DnDId,
  ISlot,
  IEnrollment,
  IOverload,
  IRuleInvocation,
  RuleTemplateType,
} from "store/types";
import sortBy from "lodash/sortBy";
import sum from "lodash/sum";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import range from "lodash/range";
import { isYearLong } from "./subjects";
import { findStudyPeriod, orderedStudyPeriods } from "./plan";
import { Permutation } from "js-combinatorics";
import { orderBy } from "lodash";
import { featureToggles } from "config/featureToggles";

export const getSlot = (id: Partial<ISlotIdentifier>, studyPeriods: IStudyPeriod[]) =>
  id.slotIndex !== undefined
    ? studyPeriods
        .find((sp) => sp.type === id.semester && sp.year === id.year)
        ?.slots.find((slot) => slot.sequence === id.slotIndex)
    : null;

const weigthedSlot = (id: ISlotIdentifier) => {
  let semIdx = 0;
  switch (id.semester) {
    case Semester.SUMMER:
      semIdx = 1;
      break;
    case Semester.SEM1:
      semIdx = 2;
      break;
    case Semester.WINTER:
      semIdx = 3;
      break;
    case Semester.SEM2:
      semIdx = 4;
      break;
  }

  return id.year * 100 + semIdx;
};

export const getPossibleDestinations = (
  id: Partial<ISlotIdentifier>,
  studyPeriods: IStudyPeriod[],
): ISlotIdentifier[] => {
  const otherPeriods = studyPeriods.filter((sp) => sp.year !== id.year || sp.type !== id.semester);
  const slodIds = otherPeriods.map((sp) => ({
    year: sp.year,
    semester: sp.type,
    slotIndex: sp.slots.findIndex((slot) => !slot.subjectRecordId),
  }));
  return sortBy(
    slodIds.filter((id) => id.slotIndex >= 0),
    weigthedSlot,
  );
};

export const subjectFitsSlot = (
  destination: { year: number; semester: Semester },
  subjectRecordId: string | null | undefined,
  subjects: ISubject[] | undefined,
  plan: IPlan | undefined | null,
  coursePoints: number | undefined | null,
  overLoads: IOverload[],
): boolean => {
  const subject = (subjects ?? []).find((s) => s.recordId === subjectRecordId);
  if (!subject || !plan || !subjects) {
    return false;
  }
  const allSiblingSlots = findStudyPeriod(destination, plan.studyPeriods)?.slots || [];
  const allPoints = allSiblingSlots
    .filter((slot) => slot.subjectRecordId && slot.subjectRecordId !== subjectRecordId)
    .map((slot) => slot.displayPoints);
  const totalPoints = sum(allPoints);

  // Full year subjects count as half the points
  const subjectPoints = isYearLong(subject) ? subject.points / 2 : subject.points;

  // Check if the subject fits within the semester
  const overload = getOverloadForPeriod({ ...destination, type: destination.semester }, overLoads);
  const fitsInSemester =
    totalPoints + subjectPoints <= maxPointsForSemester(destination.semester, coursePoints, overload);

  // If we assign a full year subject to Semester 1 - we also need to check if it would fit for Semester 2
  if (isYearLong(subject) && destination.semester === Semester.SEM1) {
    return (
      fitsInSemester &&
      subjectFitsSlot(
        { ...destination, semester: Semester.SEM2 },
        subjectRecordId,
        subjects,
        plan,
        coursePoints,
        overLoads,
      )
    );
  } else {
    return fitsInSemester;
  }
};

export const getMovedState = (from: DnDId, to: DnDId, studyPeriods: IStudyPeriod[]) => {
  const fromSlot = getSlot(from, studyPeriods);
  const toSlot = getSlot(to, studyPeriods);

  if (!toSlot) {
    return studyPeriods;
  }

  return studyPeriods.map((sp) => ({
    ...sp,
    slots: sp.slots.map((slot) => {
      if (sp.type === from.semester && sp.year === from.year && slot.sequence === from.slotIndex) {
        return {
          ...slot,
          subjectRecordId: toSlot.subjectRecordId,
          tags: toSlot.tags || [{ name: "isDiscipline", label: "Discipline" }],
          mmsRecordId: toSlot.mmsRecordId || to.mmsRecordId,
          courseRecordIds: toSlot.courseRecordIds ?? to.courseRecordIds ?? [],
        };
      } else if (sp.type === to.semester && sp.year === to.year && slot.sequence === to.slotIndex) {
        return {
          ...slot,
          subjectRecordId: from.subjectRecordId,
          tags: fromSlot?.tags || [{ name: "isDiscipline", label: "Discipline" }],
          mmsRecordId: fromSlot?.mmsRecordId || from.mmsRecordId,
          courseRecordIds: fromSlot?.courseRecordIds ?? from.courseRecordIds ?? [],
        };
      }

      return slot;
    }),
  }));
};

const getGridPoints = (subject: ISubject | null | undefined, sem: Semester) => {
  if (!subject) {
    return 12.5;
  }
  if (sem === Semester.ADVANCED_STANDING || !isYearLong(subject)) {
    return subject.points;
  }
  return subject.points / 2;
};

export const getOverloadForPeriod = (period: { type: Semester; year: number }, overloads: IOverload[]) =>
  overloads.find((o) => period.type === o.type && String(period.year) === String(o.year))?.pointsOverload ?? 0;

export const maxPointsForSemester = (
  semester: Semester,
  coursePts: number | undefined | null,
  overload: number | undefined | null,
) => {
  if (semester === Semester.ADVANCED_STANDING) {
    return (coursePts ?? 300) / 2;
  }

  const maxWithoutOverload = [Semester.SEM1, Semester.SEM2].indexOf(semester) >= 0 ? 50 : 25;
  return maxWithoutOverload + (overload ?? 0);
};

export const getVisibleSlotsIgnoringPts = (slots: ISlot[], semester: Semester) => {
  if (semester === Semester.ADVANCED_STANDING) {
    return slots;
  }

  const slotCount = [Semester.SEM1, Semester.SEM2].indexOf(semester) >= 0 ? 4 : 2;
  const slotsWithSubjects = slots.filter((s) => !!s.subjectRecordId).length;
  let allowedEmptySlots = Math.max(0, slotCount - slotsWithSubjects);
  const result: ISlot[] = [];
  for (const slot of slots) {
    if (slot.subjectRecordId) {
      result.push(slot);
    } else if (allowedEmptySlots > 0) {
      allowedEmptySlots--;
      result.push(slot);
    }
  }
  return result;
};

export const getVisibleSlots = (
  slots: ISlot[],
  semester: Semester,
  subjectHash: Record<string, ISubject>,
  coursePts: number,
  overload: number,
) => {
  const semCapacity = maxPointsForSemester(semester, coursePts, overload);
  const subjectPoints = sum(
    slots
      .filter((slot) => slot.subjectRecordId)
      .map((slot) => subjectHash[slot.subjectRecordId!])
      .map((s) => getGridPoints(s, semester)),
  );

  // How much "space" in terms of points we have left over
  let emptyPointsBudget = semCapacity - subjectPoints;

  const result: ISlot[] = [];
  for (const slot of slots) {
    const subject = slot.subjectRecordId && subjectHash[slot.subjectRecordId];

    // Never ignore a slot with a subject
    if (subject) {
      slot.displayPoints = getGridPoints(subject, semester);
      result.push(slot);

      // Only allow empty slots if we have left over space
    } else if (emptyPointsBudget > 0) {
      const emptyPoints = Math.min(12.5, emptyPointsBudget);
      emptyPointsBudget -= emptyPoints;
      slot.displayPoints = emptyPoints;
      result.push(slot);
    }
  }

  return result;
};

// Computes the grid sizes for the slots. Uses a 24 elements grid;
export const subjectGridRowPattern = (slots: ISlot[], semester: Semester, maxSemesterPoints = 30) => {
  const maxPoints = gridColSpan(maxSemesterPoints);
  const rowSize = maxPoints;

  const sizes: number[] = [];
  let totalSz = 0;
  for (const slot of slots) {
    const slotSize = gridColSpan(slot.displayPoints);

    // ensure we don't exceed the row's size
    sizes.push(totalSz + slotSize <= rowSize ? slotSize : rowSize - totalSz);
    totalSz += slotSize;
  }

  // Fill any left over at the end - e.g. add 12 grid slots for Summer/Winter
  if (totalSz < maxPoints) {
    sizes.push(maxPoints - totalSz);
  }

  // console.log(">> >> ", slots.length, sizes);
  return sizes;
};

interface IAdvancedStandingGridItem {
  slot?: ISlot;
  colStart: number;
  colSpan: number;
  row: number;
}

// 6.25pts -> 3 grid slots; 12.5pts -> 6 grid slots; 25pts -> 12 grid slots, etc.
const gridColSpan = (points: number) => 3 * (points / 6.25);

export const advancedStandingGrid24Pattern = (slots: ISlot[]) => {
  const result: IAdvancedStandingGridItem[] = [];
  let colStart = 0;
  let row = 0;
  for (const slot of slots) {
    const colSpan = gridColSpan(slot.displayPoints);

    if (colStart + colSpan > 24) {
      result.push({
        colStart,
        colSpan: 24 - colStart,
        row,
      });
      colStart = 0;
      row++;
    }

    result.push({
      slot,
      colStart,
      colSpan,
      row,
    });
    colStart += colSpan;
  }
  // debugger;
  return result;
};

const searchPeriod = (plan: IPlan, q: { subjectRecordId?: string; sem?: Semester; year?: number }) =>
  plan.studyPeriods.find(
    (sp) =>
      (!q.sem || sp.type === q.sem) &&
      (!q.year || String(sp.year) === String(q.year)) &&
      (!q.subjectRecordId || sp.slots.find((slot) => slot.subjectRecordId === q.subjectRecordId)),
  );

const swap = (slots: any[], indices: number[]) => {
  const result = [...slots];
  let i = 0;
  for (const ind of indices) {
    result[i++] = slots[ind];
  }
  return result;
};
const pointsBefore = (slots: ISlot[], subjectRecordId: string) => {
  let result = 0;
  for (const slot of slots) {
    if (slot.subjectRecordId === subjectRecordId) {
      break;
    }
    result += slot.displayPoints;
  }
  return result;
};

export const normaliseEnrollment = (e?: IEnrollment | null) => {
  if (!e) {
    return e;
  }
  // Deep copy so that we don't accidentally modify the original
  const result: IEnrollment = JSON.parse(JSON.stringify(e));
  result.overloads = result.overloads ?? [];

  const plan = result.plan;
  const coursePts = result.course.points ?? 300;
  const overloads = result.overloads;

  result.courseAreasOfStudy = e.courseAreasOfStudy ?? [];

  // Ensure the study periods are in order - makes our life easier
  plan.studyPeriods = orderedStudyPeriods(e.plan.studyPeriods);

  // Index the subjects on plan
  const subjectHash = keyBy(e.subjects, (s) => s.recordId);

  // Copy any year long (YL) subjects from SEM1 into SEM2
  const ylSubjects = e.subjects.filter((s) => isYearLong(s));
  const ylSubjectIdsToAlign: string[] = [];
  for (const subject of ylSubjects) {
    const sem1Period = searchPeriod(plan, { subjectRecordId: subject.recordId, sem: Semester.SEM1 });
    if (sem1Period) {
      const sem1Slot = sem1Period.slots.find((slot) => slot.subjectRecordId === subject.recordId);
      const sem2Period = plan.studyPeriods.find(
        (sp) =>
          sp.year === sem1Period.year &&
          sp.type === Semester.SEM2 &&
          !sp.slots.find((slot) => slot.subjectRecordId === subject.recordId),
      );
      if (sem2Period) {
        const firstEmptySlot = sem2Period.slots.find((slot) => !slot.subjectRecordId);
        Object.assign(firstEmptySlot!, omit(sem1Slot, "id", "sequence"));
        firstEmptySlot!.disabled = true;

        // We added the subject to Sem 2 - we'll need to align it under the Sem 1 slot. We'll do that at the end
        ylSubjectIdsToAlign.push(subject.recordId);
      }
    }
  }

  // Calculate which slots will be visible in the UI
  for (let i = 0; i < plan.studyPeriods.length; i++) {
    const sp = plan.studyPeriods[i];
    const overload = getOverloadForPeriod(sp, overloads);
    sp.slots = getVisibleSlots(sp.slots, sp.type, subjectHash, coursePts, overload);
  }

  // Align the YL subjects in Sem1 and Sem2
  for (const subjectRecordId of ylSubjectIdsToAlign) {
    // Get the slot indices in both Sem1 and Sem2
    const fySem1Period = searchPeriod(e.plan, { subjectRecordId, sem: Semester.SEM1 })!;

    const fySem2Period = searchPeriod(plan, {
      subjectRecordId,
      sem: Semester.SEM2,
      year: fySem1Period.year,
    })!;

    // Get all slot indices - e.g. [0, 1, 2, 3]
    const sem1Indices = range(fySem1Period.slots.length);
    const sem2Indices = range(fySem2Period.slots.length);

    // Loop through the permutations until the Sem1 and Sem2 slots for the YL subjects align
    const sem2Perms = new Permutation(sem2Indices);
    const sem1Perms = new Permutation(sem1Indices);
    breakLoops: for (const sem1Perm of sem1Perms) {
      const sem1Slots = swap(fySem1Period.slots, sem1Perm);
      for (const sem2Perm of sem2Perms) {
        const sem2Slots = swap(fySem2Period.slots, sem2Perm);

        if (pointsBefore(sem1Slots, subjectRecordId) === pointsBefore(sem2Slots, subjectRecordId)) {
          fySem1Period.slots = sem1Slots;
          fySem2Period.slots = sem2Slots;
          break breakLoops;
        }
      }
    }
  }

  // Defaults for legacy plans (before diplomas)
  for (const sp of plan.studyPeriods) {
    for (const slot of sp.slots) {
      if (slot.subjectRecordId && (slot.courseRecordIds ?? []).length === 0) {
        slot.courseRecordIds = [plan.courseRecordId];
      }
    }
  }
  result.plan.secondaryCourses = result.plan.secondaryCourses ?? [];

  // Ensure order is always alphabetical
  result.allowedComponents = orderBy(result.allowedComponents, (c) => c.name);
  result.allowedSecondaryCourses = orderBy(result.allowedSecondaryCourses, (c) => c.name);

  // Now handle placeholders - determine the placeholder count for all levels
  // level is considered a year for disciplines
  // semester 1 & 2 considered for breadth subjects
  if (featureToggles.subjectPlaceholders && plan.showPlaceholders) {
    const disciplines = new Array(Math.round(result.plan.studyPeriods.length / 2)).fill(0);
    const breadths = new Array(
      result.plan.studyPeriods.filter((sp) => sp.type === Semester.SEM1 || sp.type === Semester.SEM2).length,
    ).fill(0);
    result.validation.invocations.forEach((i: IRuleInvocation) => {
      if (
        i.ruleType === RuleTemplateType.PointsConstraint &&
        i.progress?.tags &&
        i.progress.tags.filter((t) => t.name === "IsPlaceholderRule").length > 0
      ) {
        const count = Math.round(
          ((i.progress.minimum != null ? i.progress.minimum : 0) - i.progress.currentCount) / 12.5,
        );
        i.progress.tags.forEach((t) => {
          if (t.label.startsWith("PlaceholderRuleLevel_")) {
            const tagElms = t.label.split("_");
            disciplines[Number(tagElms[1]) - 1] += count;
          } else if (t.label.startsWith("PlaceholderRuleBreadth")) {
            // distribute one per semester while skipping semesters that already
            // have a breadth subject or there are no free slots to place it in
            let value = count;
            for (let i = 0; i < breadths.length; i++) {
              if (value > 0 && !hasBreadthSubjectOrNoFreeSlots(result, i)) {
                breadths[i] += 1;
                value--;
              }
            }
          }
        });
      }
    });
    // Add the disciplines and breadth tags for each slot across the study periods
    // study periods are in year order with sem 1 and sem 2 (skip winter and summer terms)
    // levels can bleed into the next level if the plan is started mid-year and there are placeholders left over
    let level = 0;
    let i = 0;
    plan.studyPeriods.forEach((sp) => {
      if (sp.type === Semester.SEM1 || sp.type === Semester.SEM2) {
        // Breadth placeholder populates from the right
        if (breadths[i] > 0) {
          sp.slots
            .slice()
            .reverse()
            .forEach((slot) => {
              if (
                !slot.subjectRecordId &&
                slot.tags.findIndex((t) => t.name === "PlaceholderRule") === -1 &&
                breadths[i] > 0
              ) {
                slot.tags.push({ name: "PlaceholderRule", label: "BREADTH" });
                breadths[i]--;
              }
            });
        }
        if (disciplines[level] > 0 || (level >= 1 && disciplines[level - 1] > 0)) {
          sp.slots.forEach((slot) => {
            if (
              !slot.subjectRecordId &&
              slot.tags.findIndex((t) => t.name === "PlaceholderRule") === -1 &&
              (disciplines[level] > 0 || (level >= 1 && disciplines[level - 1] > 0))
            ) {
              if (level >= 1 && disciplines[level - 1] > 0) {
                slot.tags.push({ name: "PlaceholderRule", label: `DISCIPLINE:Level ${level}` });
                disciplines[level - 1]--;
              } else {
                slot.tags.push({ name: "PlaceholderRule", label: `DISCIPLINE:Level ${level + 1}` });
                disciplines[level]--;
              }
            }
          });
        }
        if (sp.type === Semester.SEM2) {
          level++;
        }
        i++;
      }
    });
  }
  return result;
};

const hasBreadthSubjectOrNoFreeSlots = (e: IEnrollment, index: number) => {
  // get the subjects in the study period and for each one check if its a breadth subject
  // also check there is a free slot to place the breadth placholder
  let slotUsedCount = 0;
  for (const slot of e.plan.studyPeriods[index].slots) {
    if (slot.subjectRecordId) {
      const subject = e.subjects.find((s) => s.recordId === slot.subjectRecordId);
      if (subject) {
        slotUsedCount++;
        if (subject.availableAsBreadth.findIndex((av) => av.code === e.course.code) >= 0) {
          return true;
        }
      }
    }
  }
  return slotUsedCount === e.plan.studyPeriods[index].slots.length;
};
