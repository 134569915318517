import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { openSnackBarWithError } from "../../actions/snackBar";
import { setSubmittingChanges, START_SUBMITTING, STOP_SUBMITTING } from "../../actions/submittingChanges";
import { IPlan } from "store/types";
import { $delete } from "lib/fetch";

interface IProps {
  open: boolean;
  close: () => any;
  plan: IPlan;
}

export const DeletePlanDialog = ({ plan, open, close }: IProps) => {
  const dispatch = useDispatch();

  const planId = plan.id;
  const planName = plan.name;

  const deletePlan = useCallback(async () => {
    close();
    if (planId) {
      try {
        dispatch(setSubmittingChanges(START_SUBMITTING));
        await $delete(`/v1/account/plan/${planId}`);
      } catch (_e) {
        dispatch(openSnackBarWithError(`Could not delete plan`));
      } finally {
        dispatch(setSubmittingChanges(STOP_SUBMITTING));
      }
    }
  }, [close, dispatch, planId]);

  if (!open || !planId) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="delete-plan-dialog-title"
      aria-describedby="delete-plan-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="Delete-plan-dialog-title">Delete plan</DialogTitle>
      <DialogContent>
        This will delete your plan &lsquo;{planName}&rsquo; permanently and cannot be undone.
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained">
          Cancel
        </Button>
        <Button onClick={deletePlan} color="primary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
