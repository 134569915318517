import React from "react";
import { Grid } from "@mui/material";

interface IProps {
  list: any[];
  title: string;
  allSubjects: any;
}

export const ImportSubjectList = ({ allSubjects, title, list }: IProps) => {
  const getSubjectName = (code: string) => {
    const data = allSubjects.find((s: any) => s.recordId === code);
    if (data) {
      return `${data.code} | ${data.name}`;
    }
    return code;
  };

  return (
    <>
      <Grid item xs={12}>
        <h4>{title}</h4>
      </Grid>
      {list.length === 0 && <p style={{ padding: 0, margin: "0px 0px 0px 10px" }}>None</p>}
      {list.length > 0 &&
        list.map((s, i) => (
          <Grid key={`s_${i}`} xs={12}>
            <p style={{ padding: 0, margin: "0px 0px 0px 10px" }}>{getSubjectName(s)}</p>
          </Grid>
        ))}
    </>
  );
};
