import { $delete, get, post, put } from "lib/fetch";
import { INewNameFormVals } from "../containers/planAction/ChangeNameDialog";
export const ADMIN_AUTH = "admin/AUTH";
export const REMOVE_TEMPLATE = "admin/REMOVE_TEMPLATE";
export const GET_TEMPLATE_DEFINITIONS = "admin/GET_TEMPLATE_DEFINITIONS";
export const CLONE_TEMPLATE_BY_YEAR = "admin/CLONE_TEMPLATE_BY_YEAR";
export const REMOVE_ELIGIBILITY = "admin/REMOVE_ALL_ELIGIBILITY";
export const ADD_ELIGIBILITY = "admin/ADD_ELIGIBILITY";
export const RENAME_PLAN = "admin/RENAME_PLAN";

export const GET_ALL_ELIGIBILITY = "admin/GET_ALL_ELIGIBILITY";
export const UPDATE_ELIGIBILITY = "admin/UPDATE_ELIGIBILITY";

export const ADD_DISCONTINUED_MMS = "admin/ADD_DISCONTINUED_MMS";
export const GET_ALL_DISCONTINUED_MMS = "admin/GET_ALL_DISCONTINUED_MMS";
export const UPDATE_DISCONTINUED_MMS = "admin/UPDATE_DISCONTINUED_MMS";
export const REMOVE_DISCONTINUED_MMS = "admin/REMOVE_DISCONTINUED_MMS";

// const sleep = (t: number) => new Promise((resolve, _reject) => setTimeout(() => resolve(""), t * 1000));
export const adminAuth = (credentials: { user: string; password: string }) => {
  return {
    type: ADMIN_AUTH,
    payload: post(`/v1/adminAuth`, {
      username: credentials.user,
      password: credentials.password,
    }).then((resp) => ({
      ...credentials,
      ...resp,
    })),
  };
};

export const renamePlan = (planId: string, payload: INewNameFormVals) => {
  return {
    type: RENAME_PLAN,
    payload: put(`/v1/enrollment/${planId}/rename`, payload),
  };
};

export const getTemplateSummaries = () => {
  return {
    type: GET_TEMPLATE_DEFINITIONS,
    payload: get(`/v1/enrollment/templatesDefinitions`),
  };
};

export const removeTemplate = (templateId: string) => {
  return {
    type: REMOVE_TEMPLATE,
    payload: $delete(`/v1/enrollment/${templateId}`),
  };
};

export const cloneTemplatesByYear = (templateIds: string[], year: string | number) => {
  return {
    type: CLONE_TEMPLATE_BY_YEAR,
    payload: post(`/v1/enrollment/cloneTemplateByYear`, { templateId: templateIds, year }),
  };
};

// Eligibility
export const addCompEligibility = (payload: any) => {
  return {
    type: ADD_ELIGIBILITY,
    payload: post(`/v1/comp-eligibility`, payload),
  };
};

export const getAllCompEligibility = () => {
  return {
    type: GET_ALL_ELIGIBILITY,
    payload: get(`/v1/comp-eligibility/all`),
  };
};

export const updateCompEligibility = (payload: any) => {
  return {
    type: UPDATE_ELIGIBILITY,
    payload: put(`/v1/comp-eligibility`, payload),
  };
};

export const removeCompEligibility = (payload: any) => {
  return {
    type: REMOVE_ELIGIBILITY,
    payload: $delete(`/v1/comp-eligibility/${encodeURIComponent(payload.recordKey)}`),
  };
};

// Discontinued MMS
export const addDiscontinuedMMS = (payload: any) => {
  return {
    type: ADD_DISCONTINUED_MMS,
    payload: post(`/v1/discontinued-mms`, payload),
  };
};

export const getAllDiscontinuedMMS = () => {
  return {
    type: GET_ALL_DISCONTINUED_MMS,
    payload: get(`/v1/discontinued-mms/all`),
  };
};

export const updateDiscontinuedMM = (payload: any) => {
  return {
    type: UPDATE_DISCONTINUED_MMS,
    payload: put(`/v1/discontinued-mms`, payload),
  };
};

export const removeDiscontinuedMM = (payload: any) => {
  return {
    type: REMOVE_DISCONTINUED_MMS,
    payload: $delete(`/v1/discontinued-mms/${encodeURIComponent(payload.recordKey)}`),
  };
};
