import { getAllKnownSubjects, getSubject } from "lib/subjects";
import React from "react";
import { useSelector } from "react-redux";
import { ISlot, ISlotIdentifier, IState } from "store/types";
import { DraggableSubjectSlotCard } from "./draggableSubjectSlotCard";
import { EmptySlotCard } from "./emptySlotCard";

interface IProps extends ISlotIdentifier {
  displaySlot: ISlot;
}

export const SlotCard = React.memo(({ displaySlot, ...props }: IProps) => {
  const allSubjects = useSelector((s: IState) => getAllKnownSubjects(s));
  const subject = getSubject(allSubjects, displaySlot?.subjectRecordId);

  return subject ? (
    <DraggableSubjectSlotCard displaySlot={displaySlot} {...props} />
  ) : (
    <EmptySlotCard tags={displaySlot.tags} {...props} />
  );
});
