interface IFeatureToggles {
  discoverySimilarSubjects?: boolean;
  dynamicYearIntake?: boolean;
  templateAuth: boolean;
  secondaryMMSColour?: boolean;
  highlightSubjectsFromChecklist?: boolean;
  editNotes?: boolean;
  refreshButton?: boolean;
  allowBreadthSubjectMMSAssignment?: boolean;
  removeCourse?: boolean;
  eligibilityRule?: boolean;
  componentEligibility?: boolean;
  allowStandAloneDiploma?: boolean;
  semesterOverloads?: boolean;
  sampleTemplate?: boolean;
  planAuth?: boolean;
  siteCrawl?: boolean;
  streamAndEntry?: boolean;
  discontinuedMMS?: boolean;
  checkMMSPoints?: boolean;
  subjectRuleFailureIndicators?: boolean;
  duplicatePlan?: boolean;
  subjectPlaceholders?: boolean;
  satisyRequisites?: boolean;
  authenticatedUser?: boolean;
  importSubjects?: boolean;
  previewWorkspaces?: boolean;
}

const allFeatureToggles: Record<string, IFeatureToggles> = {
  localDev: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: true,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    allowStandAloneDiploma: true,
    semesterOverloads: true,
    sampleTemplate: true,
    streamAndEntry: true,
    discontinuedMMS: true,
    checkMMSPoints: false,
    subjectRuleFailureIndicators: true,
    duplicatePlan: true,
    subjectPlaceholders: true,
    satisyRequisites: true,
    authenticatedUser: true,
    importSubjects: true,
    previewWorkspaces: true,
  },
  development: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: true,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    allowStandAloneDiploma: true,
    semesterOverloads: true,
    sampleTemplate: true,
    streamAndEntry: true,
    discontinuedMMS: true,
    checkMMSPoints: false,
    subjectRuleFailureIndicators: true,
    duplicatePlan: true,
    subjectPlaceholders: true,
    satisyRequisites: true,
    authenticatedUser: true,
    importSubjects: true,
    previewWorkspaces: true,
  },
  test: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: true,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    allowStandAloneDiploma: false,
    semesterOverloads: true,
    sampleTemplate: true,
    planAuth: true,
    siteCrawl: true,
    streamAndEntry: true,
    discontinuedMMS: true,
    checkMMSPoints: false,
    subjectRuleFailureIndicators: true,
    duplicatePlan: true,
    subjectPlaceholders: true,
    satisyRequisites: true,
    authenticatedUser: true,
    importSubjects: false,
    previewWorkspaces: true,
  },
  staging: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: false,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    allowStandAloneDiploma: false,
    semesterOverloads: true,
    sampleTemplate: true,
    planAuth: true,
    siteCrawl: true,
    streamAndEntry: true,
    discontinuedMMS: true,
    checkMMSPoints: false,
    subjectRuleFailureIndicators: true,
    duplicatePlan: true,
    subjectPlaceholders: true,
    satisyRequisites: true,
    authenticatedUser: false,
    importSubjects: false,
    previewWorkspaces: false,
  },
  production: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: false,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    semesterOverloads: true,
    sampleTemplate: true,
    streamAndEntry: true,
    discontinuedMMS: true,
    checkMMSPoints: false,
    subjectRuleFailureIndicators: true,
    duplicatePlan: true,
    subjectPlaceholders: true,
    satisyRequisites: true,
    authenticatedUser: false,
    importSubjects: false,
    previewWorkspaces: false,
  },
};
allFeatureToggles["default"] = allFeatureToggles.development;

const { DEPLOYMENT_ENVIRONMENT } = ((window ?? {}) as any).env ?? {};
const { NODE_ENV } = process.env;
const envKey = NODE_ENV === "development" ? "localDev" : DEPLOYMENT_ENVIRONMENT!;

export const featureToggles = allFeatureToggles[envKey];
