import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { openSnackBarWithError } from "../../actions/snackBar";
import { setSubmittingChanges, START_SUBMITTING, STOP_SUBMITTING } from "../../actions/submittingChanges";
import { post } from "lib/fetch";
import { FormControl } from "@mui/material";
import { TextField } from "formik-mui";
import { Formik, Field, FormikHelpers } from "formik";
import { validateValuePresence } from "lib/validation";
import { isEmpty } from "lodash";

interface IPlanSummary {
  id?: string;
  name: string;
}

interface IProps {
  open: boolean;
  close: () => any;
  plan?: IPlanSummary;
  focusOnNewPlan: boolean;
}

export interface IDuplicateNameFormVals {
  name: string;
}

export const DuplicateTemplateDialog = ({ plan, open, focusOnNewPlan, close }: IProps) => {
  const dispatch = useDispatch();

  const planId = plan?.id;
  const planName = plan?.name?.trim();

  const initialValues: IDuplicateNameFormVals = { name: planName || "" };

  const onSubmit = useCallback(
    async (values: IDuplicateNameFormVals, helpers: FormikHelpers<IDuplicateNameFormVals>) => {
      close();
      if (planId) {
        try {
          dispatch(setSubmittingChanges(START_SUBMITTING));
          const payload = await post(`/v1/enrollment/${planId}/duplicatePlan`, { name: values.name });
          if (payload && payload.planId && focusOnNewPlan) {
            window.open(`${window.location.origin}/plan/${payload.planId}`, "_blank")?.focus();
          }
        } catch (_e) {
          dispatch(openSnackBarWithError(`Could not duplicate plan`));
        } finally {
          dispatch(setSubmittingChanges(STOP_SUBMITTING));
          helpers.setSubmitting(false);
        }
      }
    },
    [close, dispatch, focusOnNewPlan, planId],
  );

  const validateFormState = useCallback((values: IDuplicateNameFormVals) => {
    const errors: any = {};
    validateValuePresence(errors, values, "name");
    return errors;
  }, []);

  if (!open || !planId) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validate={validateFormState} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="duplicate-plan-dialog-title"
          aria-describedby="duplicate-plan-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="duplicate-plan-dialog-title">Name your duplicate plan</DialogTitle>
          <DialogContent>
            <form onSubmit={props.handleSubmit} id="duplicate-plan-form">
              <FormControl fullWidth variant="outlined" required>
                <Field
                  // style={{ width: `min(80vw, 400px)` }}
                  component={TextField}
                  sx={{ margin: "4px 1px" }}
                  name="name"
                  label={`New name`}
                  fullWidth={true}
                  variant="outlined"
                  required={true}
                  inputProps={{
                    "aria-label": `New name`,
                    required: true,
                  }}
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="duplicate-plan-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
