import React, { useState } from "react";
import { Typography, Dialog, DialogContent, IconButton, TextField, Button, useTheme } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { IPlan, IState } from "store/types";
import { brandBlue, fadedBrandBlue1, fadedBrandBlue2 } from "theme/colors";
import { Theme } from "@mui/material/styles";

const useStyles = (theme: Theme) => ({
  input: {
    paddingRight: "0px",
    backgroundColor: theme.palette.background.paper,
    color: brandBlue.toString(),
  },
  button: {
    alignSelf: "stretch",
    backgroundColor: fadedBrandBlue2.toString(),
    color: "white",
    fontWeight: "bold",
    borderRadius: "0px 4px 4px 0px",
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: fadedBrandBlue1.toString(),
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px 0px 24px",
  },
  dialogTitle: {
    alignSelf: "center",
  },
  dialogContent: {
    padding: "0px 24px 24px 24px",
  },
  typographyGutterBottom: {
    marginBottom: "1.5em",
  },
  helperText: {
    marginLeft: 0,
  },
});

interface IProps {
  handleClose: () => any;
  open: boolean;
  thePlan?: IPlan;
  link?: string;
}

export const ShareLinkModal = ({ handleClose, open, thePlan, link }: IProps) => {
  const { plan } = useSelector((state: IState) => state.enrollment!) || { plan: thePlan };
  const theme = useTheme();
  const styles = useStyles(theme);
  const [copyBtnText, setCopyBtnText] = useState("Copy");
  const copyLink = (_event: React.MouseEvent) => {
    (document.getElementById("share-link-read-only-input") as HTMLInputElement).select();
    document.execCommand("copy");
    setCopyBtnText("Copied!");
  };

  const handleCloseModal = () => {
    handleClose();
    setCopyBtnText("Copy");
  };

  const DialogTitle = ({ children, onClose, ...other }: any) => (
    <MuiDialogTitle sx={styles.dialogHeader} disableTypography {...other}>
      <Typography sx={styles.dialogTitle} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={handleCloseModal}
      aria-labelledby="share-link-dialog-title"
    >
      <DialogTitle id="share-link-dialog-title" onClose={handleCloseModal}>
        Share link
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Typography
          variant="subtitle1"
          gutterBottom
          id="share-link-input-label"
          color="textSecondary"
          sx={{ gutterBottom: styles.typographyGutterBottom }}
        >{`Share your ‘${plan.name}’ with Stop 1 Student Advisors`}</Typography>
        <TextField
          id="share-link-read-only-input"
          variant="outlined"
          fullWidth={true}
          InputProps={{
            sx: styles.input,
            readOnly: true,
            endAdornment: (
              <Button sx={styles.button} onClick={copyLink} aria-live="polite">
                {copyBtnText}
              </Button>
            ),
          }}
          inputProps={{ "aria-labelledby": "share-link-input-label" }}
          defaultValue={link || window.location.href}
          helperText="Anyone with this link can access this plan"
          FormHelperTextProps={{ sx: { contained: styles.helperText } }}
        />
      </DialogContent>
    </Dialog>
  );
};
