import { createEnrollment, ICreatePlanPayload } from "actions/enrollment";
import { ResponsiveCenter } from "components/spinner/Center";
import { setWorkspaceId } from "lib/workspaceId";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { YearIntake } from "store/types";
import { PlanContainer } from "./PlanContainer";
import { AppBar, Button, Theme, Toolbar, useTheme } from "@mui/material";
import { Aligned } from "components/aligned/alignedDiv";
import styled from "styled-components";
import { $delete, get } from "lib/fetch";
import { setSubmittingChanges, START_SUBMITTING, STOP_SUBMITTING } from "actions/submittingChanges";
import { openSnackBarWithError } from "actions/snackBar";
import { useNavigate } from "@reach/router";
import logo from "../assets/images/logo-with-padding.svg";
import { Link } from "@reach/router";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

interface IProps {
  path?: string;
  courseCode?: string;
  planYear?: string;
  workspaceId?: string;
  id?: string;
  children?: any | null;
}

const StyledToolbar = styled(Toolbar)`
  padding-left: 0rem !important;
`;

const useStyles = (theme: Theme) => ({
  appBar: {
    background: "purple",
  },
  navIcon: {
    fill: theme.palette.common.white,
  },
  navButton: {
    color: theme.palette.common.white,
    marginRight: "1rem",
    textTransform: "none",
  },
});

const Img = styled.img`
  max-height: 5rem;
`;

export const PreviewPlanContainer = ({ courseCode, planYear, workspaceId, id, children }: IProps) => {
  const [isLoading, setLoading] = useState(true);
  const [workspace, setWorkspace] = useState<any>(null);
  const [planId, setPlanId] = useState<any>(null);
  const theme = useTheme();
  const styles = useStyles(theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  setWorkspaceId(workspaceId);

  useEffect(() => {
    const createPlan = async () => {
      try {
        setLoading(true);
        dispatch(setSubmittingChanges(START_SUBMITTING));
        const workspace = await get(`/v1/workspaces/${workspaceId}`);
        setWorkspace(workspace);
        dispatch(setSubmittingChanges(STOP_SUBMITTING));
        if (id) {
          setPlanId(id);
          setLoading(false);
          return;
        }
        const payload: ICreatePlanPayload = {
          planName: "CPT Preview",
          planCourse: courseCode!,
          planCourseLevel: "Undergraduate",
          planYear: planYear!,
          isMidyearEntry: false,
          entryRecordId: "",
          isNewCreation: true,
          streamRecordId: "",
          yearIntake: YearIntake.START_YEAR,
        };
        const submitResult = dispatch(createEnrollment(payload as ICreatePlanPayload)) as any;
        submitResult
          .then((response: any) => {
            setLoading(false);
            setPlanId(response.action.payload.plan.id);
            navigate(`/preview-plan/${courseCode}/${planYear}/${workspaceId}/${response.action.payload.plan.id}`);
          })
          .catch(() => setLoading(false));
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    createPlan();
  }, [courseCode, dispatch, id, navigate, planYear, workspaceId]);

  const onRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const onClosePreview = useCallback(async () => {
    if (planId) {
      try {
        dispatch(setSubmittingChanges(START_SUBMITTING));
        await $delete(`/v1/account/plan/${planId}`);
        await $delete(`/v1/workspaces/${workspaceId}`);
        setPlanId(null);
        setWorkspaceId(undefined);
        setWorkspace(null);
      } catch (_e) {
        dispatch(openSnackBarWithError(`Could not close CPT Preview`));
      } finally {
        dispatch(setSubmittingChanges(STOP_SUBMITTING));
      }
    }
  }, [dispatch, planId, workspaceId]);

  const getItemDescription = () => {
    const item = workspace && workspace.items[0];
    return item ? `${item.name} | ${item.code} | v${item.version}` : "unknown";
  };

  return (
    <>
      {!isLoading && planId && (
        <>
          <AppBar position="static" className={`do-not-print`} sx={styles.appBar}>
            <ResponsiveCenter>
              <Aligned style={{ justifyContent: "space-between" }}>
                <StyledToolbar>
                  <Img src={logo} alt="University of Melbourne Logo" />
                  <Aligned style={{ flexDirection: "column", alignItems: "start" }}>
                    <Link style={{ whiteSpace: "nowrap" }} to="#" className="page-title">
                      My Course Planner Preview
                      <br />
                      <Aligned>
                        <WorkspacesIcon sx={{ fill: "white", fontSize: "20px" }} />
                        &nbsp;
                        <p style={{ fontSize: "85%" }}>{getItemDescription()}</p>
                      </Aligned>
                    </Link>
                  </Aligned>
                </StyledToolbar>
                <div>
                  <Button
                    variant="contained"
                    sx={styles.navButton}
                    onClick={onRefresh}
                    endIcon={<RefreshIcon sx={{ fill: "white" }} />}
                  >
                    Refresh
                  </Button>
                  <Button
                    variant="contained"
                    sx={styles.navButton}
                    onClick={onClosePreview}
                    endIcon={<CloseIcon sx={{ fill: "white" }} />}
                  >
                    Close Preview
                  </Button>
                </div>
              </Aligned>
            </ResponsiveCenter>
          </AppBar>
          <PlanContainer id={planId} preview={true}>
            {children}
          </PlanContainer>
        </>
      )}
      {!isLoading && !planId && (
        <ResponsiveCenter>
          <br />
          <h3 style={{ textAlign: "center" }}>You can now close this browser tab</h3>
        </ResponsiveCenter>
      )}
    </>
  );
};
