import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { HintToolTip, ICourse, IPlan, Semester } from "store/types";
import { removeYear, flipStudyPeriod } from "actions/enrollment";
import { useDispatch } from "react-redux";
import { Aligned } from "components/aligned/alignedDiv";
import { StudyPeriodMenu } from "./StudyPeriodMenu";
import { Hint } from "./Hint";
import { useMenuState } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { Collapse } from "@mui/material";
import { usePlanPermissions } from "lib/usePlanPermissions";
const H2 = styled.h2`
  color: black;
  margin-top: 0rem;
  margin-bottom: 0rem;
  display: inline-block;
`;
const HeaderContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 0rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const HeaderNote = styled.div`
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding: 0;
  font-style: italic;
  font-size: small;
`;

export interface IYearHeaderProps {
  yearIndex: number;
  year: number;
  plan: IPlan;
  course: ICourse;
  collapsed: boolean;
  flipCollapsedYear: (year: number | string) => any;
}

export const YearHeader = (props: IYearHeaderProps) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  // Menu actions
  const doRemoveYear = (year: number) => year && dispatch(removeYear({ planId: props.plan.id!, year })!);
  const doFlipSemester = (year: number, sem: Semester) => dispatch(flipStudyPeriod(props.plan.id!, year, sem));

  const { year, flipCollapsedYear } = props;
  const flipExpansion = useCallback(() => flipCollapsedYear(year), [flipCollapsedYear, year]);

  const { readOnlyPlan } = usePlanPermissions();

  return (
    <HeaderContainer>
      <Aligned>
        <H2>{props.year}</H2>&nbsp;&nbsp;
        {!readOnlyPlan && props.year !== props.plan.year && (
          <IconButton className="do-not-print" ref={ref} aria-label="year menu" onClick={openMenu} size="large">
            <MoreVertIcon />
          </IconButton>
        )}
        {!readOnlyPlan && props.year == props.plan.year && (
          <Hint hintType={HintToolTip.ADD_NON_STANDARD_SUBJECT} hideHint={menuProps.state === "open"} placement="right">
            <IconButton className="do-not-print" ref={ref} aria-label="year menu" onClick={openMenu} size="large">
              <MoreVertIcon />
            </IconButton>
          </Hint>
        )}
        <IconButton
          onClick={flipExpansion}
          className="do-not-print"
          aria-label={props.collapsed ? "expand year" : "collapse year"}
          size="large"
        >
          {props.collapsed ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
        </IconButton>
      </Aligned>
      {!readOnlyPlan && (
        <StudyPeriodMenu
          {...props}
          {...menuProps}
          anchorRef={ref}
          closeMenu={closeMenu}
          removeYear={doRemoveYear}
          flipSemester={doFlipSemester}
        />
      )}
      {/* {props.yearIndex === 0 && (
        <Button
          variant="outlined"
          className={classes.addButton}
          // onClick={props.openPostgradDrawer}
          startIcon={<PlaylistAddIcon />}
        >
          Add Subjects
        </Button>
      )} */}
    </HeaderContainer>
  );
};

export interface IAdvancedStandingHeaderProps {
  collapsed: boolean;
  flipCollapsed: () => any;
}

export const AdvancedStandingHeader = (props: IAdvancedStandingHeaderProps) => {
  return (
    <>
      <HeaderContainer>
        <Aligned>
          <H2>Advanced Standing</H2>
          <IconButton
            onClick={props.flipCollapsed}
            className="do-not-print"
            aria-label={props.collapsed ? "expand advanced standing" : "collapse advanced standing"}
            size="large"
          >
            {props.collapsed ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
          </IconButton>
        </Aligned>
      </HeaderContainer>
      <Collapse className="printable-collapse" in={!props.collapsed}>
        <HeaderNote role="heading" {...({ "aria-level": "3" } as any)}>
          <p style={{ marginTop: "0rem", marginBottom: "0.25rem" }}>
            If you have been awarded general advanced standing credit, you can select a similar subject as a substitute
            for the level and type of credit you received.
          </p>
          <p style={{ marginTop: "0rem", marginBottom: "0.25rem" }}>
            For example, if you have received general credit for an Introductory film making subject, that you have
            previously completed in another course, you can add a level 1 breadth in this Advanced Standing section.
          </p>
        </HeaderNote>
      </Collapse>
    </>
  );
};
