import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { openSnackBarWithError } from "../../actions/snackBar";
import { setSubmittingChanges, START_SUBMITTING, STOP_SUBMITTING } from "../../actions/submittingChanges";
import { IPlan } from "store/types";
import { post } from "lib/fetch";
import { ImportSubjectList } from "./ImportSubjectList";
import { Grid } from "@mui/material";
import { getEnrollment } from "actions/enrollment";

interface IProps {
  open: boolean;
  close: () => any;
  plan: IPlan;
}

export const ImportSubjectsDialog = ({ plan, open, close }: IProps) => {
  const dispatch = useDispatch();
  const [results, setResults] = useState<any | null>(null);

  const planId = plan.id;

  useEffect(() => setResults(null), []);

  const importSubjects = useCallback(async () => {
    close();
    if (planId) {
      try {
        dispatch(setSubmittingChanges(START_SUBMITTING));
        await post(`/v1/enrollment/${planId}/import`, {});
        dispatch(getEnrollment(planId));
        // Designer believes do not need to tell the user of the changes
        //close();
        //setResults(response);
      } catch (_e) {
        dispatch(openSnackBarWithError(`Could not add passed subjects`));
      } finally {
        dispatch(setSubmittingChanges(STOP_SUBMITTING));
      }
    }
  }, [close, dispatch, planId]);

  const onClose = useCallback(() => {
    close();
  }, [close]);

  if (!open || !planId) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="confirm-add-enrolled-passed-subjects-dialog-title"
      aria-describedby="confirm-add-enrolled-passed-subjects-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="confirm-add-enrolled-passed-subjects-dialog-title">Add enrolled and passed subjects</DialogTitle>
      <DialogContent sx={{ maxHeight: "400px", overflowY: "auto" }}>
        {results ? (
          <>
            The following changes to your plan have been made.
            <Grid container spacing={1}>
              <ImportSubjectList title="Added Subjects" allSubjects={results.subjects} list={results.addedSubjects} />
              <ImportSubjectList
                title="Replaced Subjects"
                allSubjects={results.subjectsp}
                list={results.replacedSubjects}
              />
              <ImportSubjectList
                title="Enrolled Subjects"
                allSubjects={results.subjects}
                list={results.enrolledSubjects}
              />
            </Grid>
          </>
        ) : (
          <>
            This will add all your enrolled subjects and any of the ones you successfully passed to your plan.
            <br />
            This action may replace or remove any existing subjects in your working plan.
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          {results ? "Close" : "Cancel"}
        </Button>
        {!results && (
          <Button onClick={importSubjects} variant="contained">
            Add
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
