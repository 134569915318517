import React, { useCallback } from "react";
import { Button, Tooltip, TooltipProps, tooltipClasses, useTheme } from "@mui/material";
import { HintToolTip, IState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentHint } from "actions/enrollment";
import { hintDefinitions } from "components/hints/hintsDefinitions";
import { styled } from "@mui/material/styles";
import { closeCurrentAccountHint } from "actions/profile";

type TooltipBackground = "dark" | "light" | undefined | null;
type TooltipPropsWithBackground = TooltipProps & { background: TooltipBackground; width?: string };

const BootstrapTooltip2 = styled(({ className, ...props }: TooltipPropsWithBackground) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))((e: any) => {
  const { theme, background, width } = e;
  // eslint-disable-next-line no-debugger
  // debugger;
  return {
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        backgroundColor: background === "light" ? "#CBE8EF" : theme.palette?.common?.black,
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: background === "light" ? "#CBE8EF" : theme.palette?.common?.black,
      color: background === "light" ? theme.palette?.common?.black : theme.palette?.common?.white,
      fontSize: "0.95rem",
      padding: "1rem",
      borderRadius: "10px",
      width: width ?? "initial",
      lineHeight: "1.4em",
    },
  };
});

export interface IProps {
  children: any;
  hintType: HintToolTip;
  background?: TooltipBackground;
  hideHint?: boolean;
  width?: string;
  disablePortal?: boolean;
  disableClose?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

export const Hint = ({
  children,
  hintType,
  hideHint,
  background,
  width,
  placement,
  disablePortal,
  disableClose = false,
}: IProps) => {
  // Hints can be for the enrollment screen or account screen
  const currentHint = useSelector((s: IState) => s.enrollment?.currentHint);
  const currentAccountHint = useSelector((s: IState) => s.profile?.currentHint);
  const inocationFocused = useSelector((s: IState) => !!s.focusInvocationId);
  const loading = useSelector((s: IState) => s.loading || s.submittingChanges);
  const open =
    !inocationFocused &&
    (!!currentHint || !!currentAccountHint) &&
    !hideHint &&
    !loading &&
    !!hintType &&
    (hintType === currentHint || hintType == currentAccountHint);
  const theme = useTheme();
  // if (!currentHint || hintType !== currentHint) {
  //   return children;
  // }
  const dispatch = useDispatch();
  const close = useCallback(() => {
    dispatch(currentHint ? closeCurrentHint() : closeCurrentAccountHint());
  }, [currentHint, dispatch]);

  if (!hintType) {
    return children;
  }

  return (
    <BootstrapTooltip2
      background={background || "light"}
      width={width}
      PopperProps={{
        disablePortal: !!disablePortal,
        style: { zIndex: 1250 },
      }}
      // onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        <div className="do-not-print">
          <strong>{hintDefinitions[hintType].heading}</strong>
          <p dangerouslySetInnerHTML={{ __html: hintDefinitions[hintType].text }} />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: 0,
            }}
          >
            {!disableClose && (
              <Button onClick={close} style={{ textDecoration: "underline" }}>
                <strong
                  style={{
                    color: background !== "light" ? theme.palette.common.black : theme.palette.common.white,
                    textDecoration: "underline",
                  }}
                >
                  Got it!
                </strong>
              </Button>
            )}
          </div>
        </div>
      }
      placement={placement ?? "right-start"}
    >
      {children}
    </BootstrapTooltip2>
  );
};
