import React, { useCallback } from "react";
import { brandGrey } from "theme/colors";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import { ReactComponent as DeletePlanIcon } from "./DeletePlanIcon.svg";
import { ReactComponent as DuplicatePlanIcon } from "../planAction/menuItems/DuplicatePlanIcon.svg";
import { Icon, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "@reach/router";

interface IProps {
  closeMenu: () => any;
  anchorEl: any;
  isOpen: boolean;
  planLink: string;
  shareDialog: () => any;
  duplicateDialog: () => any;
  deleteDialog: () => any;
}

export const PlanActionMenu = (props: IProps) => {
  const { closeMenu, shareDialog, duplicateDialog, deleteDialog, planLink, anchorEl, isOpen, ...menuProps } = props;
  const navigate = useNavigate();

  const editPlan = useCallback(() => {
    navigate(planLink);
  }, [navigate, planLink]);

  return (
    <Menu
      className="do-not-print"
      anchorEl={anchorEl}
      onClose={closeMenu}
      open={isOpen}
      PaperProps={{
        style: {
          width: "150px",
          borderRadius: ".25rem",
        },
      }}
      {...menuProps}
    >
      <MenuItem onClick={editPlan} key="create-plan-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary="Edit" />
      </MenuItem>
      <MenuItem onClick={shareDialog} key="share-plan-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText primary="Share" />
      </MenuItem>
      <MenuItem onClick={duplicateDialog} key="duplicate-plan-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <Icon component={DuplicatePlanIcon} />
        </ListItemIcon>
        <ListItemText primary="Duplicate" />
      </MenuItem>
      <MenuItem onClick={deleteDialog} key="delete-plan-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <Icon component={DeletePlanIcon} />
        </ListItemIcon>
        <ListItemText primary="Delete" />
      </MenuItem>
    </Menu>
  );
};
