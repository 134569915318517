import React, { useCallback, useMemo } from "react";
import { BreadthDisciplineScope, IState, ISubject, Semester, SubjectTag } from "store/types";
import { ListItemIcon, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeSubjectTypeAssignment, removeSubject } from "actions/enrollment";
import { analyseSubject, getAllKnownSubjects, isSubjectAssignable } from "lib/subjects";
import { getPossibleDestinations, getSlot, subjectFitsSlot } from "lib/subjectGrid";
import {
  getGroupedComponents,
  getMajorsAndMinors,
  getMMSWithSpecialisationsForSubject,
  getSpecialisations,
} from "lib/components";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { featureToggles } from "config/featureToggles";
import { getSimilarSubjects } from "actions/similarSubjectsSearch";
import { useLocation, useNavigate } from "@reach/router";
import { includes, trimEnd, uniq } from "lodash";

import "@szhsin/react-menu/dist/index.css";
import "../../actionMenu.css";
import { AssignMMSSubMenu } from "./AssignMMSSubMenu";
import { AssignSubjectSubMenu } from "./AssignSubjectSubMenu";
import { AssignSecondaryCourseSubMenu } from "./AssignSecondaryCourseSubMenu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { MoveSubjectSubMenu } from "./MoveSubjectSubMenu";

export interface IProps {
  year?: number;
  htmlId: string;
  semester?: Semester;
  slotIndex?: number;
  mmsRecordId?: string;
  subjectRecordId?: string;
  subject?: ISubject;
  subjectTag?: any;
  anchorRef: React.Ref<any>;
  isDragging?: boolean;
  assignAs?: BreadthDisciplineScope;
  close: () => any;
  openDetailsModal?: () => any;
  allowMove: boolean;
}

export const SubjectMenu = (props: IProps) => {
  const {
    year,
    semester,
    slotIndex,
    anchorRef,
    close,
    openDetailsModal,
    mmsRecordId,
    htmlId,
    subjectRecordId,
    subject,
    isDragging: _isDragging,
    subjectTag: _subjecTag,
    assignAs,
    allowMove,
    ...menuProps
  } = props;

  const mainCourse = useSelector((s: IState) => s.enrollment?.course);
  const overloads = useSelector((s: IState) => s.enrollment?.overloads ?? []);
  const studyPeriods = useSelector((s: IState) => s.enrollment?.plan.studyPeriods || []);
  const mmsRecordIds = useSelector((s: IState) => s.enrollment?.plan.mmsRecordIds || []);
  const selectedSecondaryCourses = useSelector((s: IState) => s.enrollment?.plan.secondaryCourses ?? []);
  const coursePoints = useSelector((s: IState) => s.enrollment?.course?.points);
  const mmsAssignableSubjects = useSelector((s: IState) => s.enrollment?.validation?.mmsAssignableSubjects || {});
  const selectedComponents = useSelector((s: IState) => s.enrollment?.allowedComponents || []).filter(
    (c) => mmsRecordIds.indexOf(c.recordId) >= 0,
  );
  const groupedComponents = getGroupedComponents(selectedComponents);
  const majorsAndMinors = useMemo(() => getMajorsAndMinors(groupedComponents), [groupedComponents]);
  const specialisations = useMemo(() => getSpecialisations(groupedComponents), [groupedComponents]);
  const selectedSecondaryCourseDetails = useSelector((s: IState) =>
    (s.enrollment?.allowedSecondaryCourses ?? []).filter((c) =>
      selectedSecondaryCourses.find((sc) => sc.courseRecordId === c.recordId),
    ),
  );
  const selectableCourses = [mainCourse!, ...selectedSecondaryCourseDetails]
    .filter(Boolean)
    .filter((c) => isSubjectAssignable(c.code, subject));
  const selectedCoursesIds = selectableCourses.map((c) => c.recordId);

  const majorsAndMinorsWithSpecialisations = useMemo(() => {
    return subject?.recordId
      ? getMMSWithSpecialisationsForSubject(majorsAndMinors, specialisations, subject.recordId, mmsAssignableSubjects)
      : [];
  }, [majorsAndMinors, mmsAssignableSubjects, specialisations, subject?.recordId]);
  const slot = getSlot(props, studyPeriods);
  const selectedSubjectRecordId = slot?.subjectRecordId || subjectRecordId;
  const slotCourseRecordIds = useMemo(() => uniq(slot?.courseRecordIds ?? []), [slot]);

  const subjectMeta = subject ? analyseSubject(subject) : null;

  const plan = useSelector((s: IState) => s.enrollment?.plan);
  const planId = plan?.id;
  const allKnownSubjects = useSelector((s: IState) => getAllKnownSubjects(s));
  // Debug by subject code
  /*if (subject?.code === "ABPL30064") {
    console.log("test");
  }*/
  const dragDestinations = selectedSubjectRecordId
    ? getPossibleDestinations(props, studyPeriods).filter((dest) =>
        subjectFitsSlot(dest, subjectRecordId, allKnownSubjects, plan, coursePoints, overloads),
      )
    : [];

  const dispatch = useDispatch();

  const doRemove = useCallback(() => {
    if (year && semester && slotIndex !== undefined) {
      dispatch(removeSubject(planId || "", year, semester, slotIndex));
    }
  }, [dispatch, planId, year, semester, slotIndex]);

  const doChangeAssignment = useCallback(
    (assignment: string) => () => {
      // eslint-disable
      const isDiscipline = assignment === SubjectTag.CAN_BE_DISCIPLINE;
      if (selectedSubjectRecordId) {
        dispatch(changeSubjectTypeAssignment(planId || "", selectedSubjectRecordId, isDiscipline));
      }
    },
    [dispatch, planId, selectedSubjectRecordId],
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showSearch = useCallback(() => {
    navigate(`${trimEnd(pathname, "/")}/search/year/${year}/semester/${semester}/slot/${slotIndex}`);
  }, [navigate, year, semester, slotIndex, pathname]);

  const showDetails = useCallback(() => {
    if (openDetailsModal) {
      openDetailsModal();
    }
  }, [openDetailsModal]);

  const showSimilarSubjects = useCallback(() => {
    const recordId = subjectRecordId || subject?.recordId;
    if (recordId) {
      dispatch(getSimilarSubjects(recordId));
    }
  }, [dispatch, subjectRecordId, subject?.recordId]);

  const menuItems: any[] = [];

  const slotMenuListItems = [
    {
      label: "Details",
      onClick: showDetails,
      icon: <InfoOutlinedIcon />,
    },
    {
      label: "Change",
      onClick: showSearch,
      icon: <EditIcon />,
    },
    {
      label: "Remove",
      onClick: doRemove,
      icon: <Delete />,
    },
  ];

  if (slot) {
    slotMenuListItems.forEach((item: any) => {
      menuItems.push(
        <MenuItem key={`${item.label}-${selectedSubjectRecordId}`} onClick={item.onClick}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </MenuItem>,
      );
    });
  }

  if (
    subjectMeta?.canBeBreadth &&
    subjectMeta?.canBeDiscipline &&
    slot &&
    (!slot?.mmsRecordId || includes(slotCourseRecordIds, plan?.courseRecordId))
  ) {
    menuItems.push(
      <AssignSubjectSubMenu
        key="assign-subject-subMenu-component"
        subjectMeta={subjectMeta}
        selectedSubjectRecordId={selectedSubjectRecordId}
        doChangeAssignment={doChangeAssignment}
      />,
    );
  }

  if (allowMove && dragDestinations.length > 0) {
    menuItems.push(
      <MoveSubjectSubMenu
        key="move-subject-subMenu-component"
        dragDestinations={dragDestinations}
        selectedSubjectRecordId={selectedSubjectRecordId}
        slot={slot}
        year={year}
        semester={semester}
        slotIndex={slotIndex}
        mmsRecordId={mmsRecordId}
        assignAs={assignAs}
      />,
    );
  }

  if (
    (featureToggles.allowBreadthSubjectMMSAssignment || subjectMeta?.canBeDiscipline || subjectMeta?.isMMSAssigned) &&
    majorsAndMinorsWithSpecialisations.length > 0 &&
    slot
  ) {
    menuItems.push(
      <AssignMMSSubMenu
        key="assign-mms-subMenu-component"
        subject={subject}
        selectedSubjectRecordId={selectedSubjectRecordId}
        selectedCoursesIds={selectedCoursesIds}
        selectedComponents={selectedComponents}
        subjectMeta={subjectMeta}
        slot={slot}
      />,
    );
  }

  // if (subject?.code === "MUSI20164") {
  //   console.log(">>>>", selectableCourses);
  // }
  if (selectableCourses.length > 1 && slot) {
    menuItems.push(
      <AssignSecondaryCourseSubMenu
        key="assign-secondary-course-subMenu-component"
        selectableCourses={selectableCourses}
        slotCourseRecordIds={slotCourseRecordIds}
        subjectRecordId={subjectRecordId}
        slot={slot}
      />,
    );
  }

  if (featureToggles.discoverySimilarSubjects && slot) {
    menuItems.push(
      <MenuItem key={`similar-subjects-${selectedSubjectRecordId}`} onClick={showSimilarSubjects}>
        <ListItemIcon>
          <img src="/watson-black.png" alt="IBM Watson" style={{ width: 25, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Similar Subjects" />
      </MenuItem>,
    );
  }

  return (
    <ControlledMenu
      id={htmlId}
      // children={menuItems as any[]}
      {...{ ...menuProps, menuItemFocus: { position: "initial" } }}
      anchorRef={anchorRef}
      onClose={close}
    >
      {menuItems}
    </ControlledMenu>
  );
};
