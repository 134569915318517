import { ISubjectQuery } from "store/types";

export const Name = [];
export const Level = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
export const StudyType = ["Coursework", "Research"];

export interface IAreaOfStudy {
  code: string;
  description: string;
}

export const OrgUnits = [
  "Melbourne School of Land and Environment",
  "Medley Hall",
  "International House",
  "Melbourne University Sport",
  "Centre for Neuroscience Research",
  "Languages and Linguistics",
  "Social and Political Sciences",
  "Asia Institute",
  "Historical and Philosophical Studies",
  "Culture and Communication",
  "Graduate School of Humanities and Social Sciences",
  "Biomedical Engineering",
  "Chemical Engineering",
  "Finance",
  "Melbourne Institute of Applied Economic and Social Research",
  "Accounting",
  "Management and Marketing",
  "Melbourne Entrepreneurial Centre",
  "Economics",
  "Melbourne School of Professional and Continuing Education",
  "Graduate School of Business and Economics",
  "Business Administration",
  "Legal and Risk",
  "Operational Performance Group",
  "Business Services",
  "Student and Scholarly Services",
  "Research, Innovation and Commercialisation",
  "Property",
  "Business Intelligence and Reporting",
  "Digital and Data",
  "Project Services",
  "Chief Financial Officer Group",
  "Student Accommodation Unit",
  "Infrastructure Engineering",
  "Mechanical Engineering",
  "Electrical and Electronic Engineering",
  "Biomedical and Chemical Engineering",
  "Computing and Information Systems",
  "Electrical, Mechanical and Infrastructure Engineering",
  "VCA and MCM",
  "Victorian College of the Arts",
  "Melbourne Conservatorium of Music",
  "Australia India Institute",
  "Asialink",
  "Melbourne School of Government",
  "Medicine - St Vincent's Hospital",
  "Medicine - Austin Health",
  "Radiology",
  "Medicine, Western Health",
  "Medicine - Royal Melbourne Hospital",
  "Medicine, Northern Health",
  "MHRI Department of Neuroscience in Mental Health",
  "School of Biomedical Sciences",
  "Melbourne Dental School",
  "The Sir Peter MacCallum Department of Oncology",
  "Doherty Institute",
  "Centre for Cancer Research",
  "Florey Department of Neuroscience and Mental Health",
  "Medical Biology(W.E.H.I.)",
  "Melbourne Medical School",
  "Melbourne School of Population and Global Health",
  "Centre for Digital Transformation of Health",
  "Centre for Youth Mental Health",
  "School of Health Sciences",
  "Melbourne School of Psychological Sciences",
  "Medical Bionics Department",
  "North West Academic Centre",
  "Clinical School - St Vincent's Hospital",
  "Eastern Hill Academic Centre",
  "Clinical School - Austin Health",
  "Psychiatry",
  "General Practice",
  "Austin Academic Centre",
  "Rural Health",
  "Clinical Pathology",
  "Medical Education",
  "Royal Melbourne Hospital Academic Centre",
  "Obstetrics and Gynaecology Royal Women's Hospital/Mercy",
  "Clinical School - Royal Melbourne Hospital",
  "Rural Health Academic Centre",
  "Surgery",
  "Baker Department of Cardiometabolic Health",
  "Paediatrics Royal Children's Hospital",
  "Clinical Pathology",
  "Medicine and Radiology",
  "Nossal Institute for Global Health",
  "Veterinary Clinical Sciences",
  "Veterinary Biosciences",
  "MDU Microbiology",
  "Atlantic Fellows for Social Equity",
  "Microbiology and Immunology",
  "Anatomy and Neuroscience",
  "Biochemistry and Molecular Biology",
  "Pathology",
  "Physiology",
  "Pharmacology and Therapeutics",
  "Audiology and Speech Pathology",
  "Social Work",
  "Optometry and Vision Sciences",
  "Nursing",
  "Physiotherapy",
  "BioSciences",
  "Physics",
  "Chemistry",
  "Geography",
  "Mathematics and Statistics",
  "Bio21 Institute",
  "Ecosystem and Forest Sciences",
  "Earth Sciences",
  "Australian Mathematical Sciences Institute",
  "Geography and Resource Management",
  "Melbourne Business School",
  "Veterinary & Agricultural Sciences",
  "Vice - Chancellor's Office",
  "UoM Commercial Ltd",
  "The Ian Potter Museum of Art",
  "Surgery - Austin Health",
  "Surgery, Northern Health",
  "Otolaryngology",
  "Ophthalmology Eye and Ear Hospital",
  "Surgery, Western Health",
  "Surgery - St Vincent's Hospital",
  "Surgery - Royal Melbourne Hospital",
  "Finance and Employee Services",
  "Infrastructure Services",
  "External Relations",
  "Commercial Services",
  "Procurement Services",
  "Agriculture and Food Systems",
  "Melbourne Veterinary School",
  "Agriculture and Food",
  "Kendall Hall",
  "Veterinary Hospital",
  "Academic",
  "International",
  "Strategy and Culture",
  "Arts",
  "Advancement",
  "University Services",
  "Administration and Finance",
  "Melbourne Graduate School of Education",
  "Architecture, Building and Planning",
  "Medicine, Dentistry and Health Sciences",
  "Engineering and Information Technology",
  "Chief Operating Officer",
  "Fine Arts and Music",
  "Engagement",
  "Veterinary and Agricultural Sciences",
  "Science",
  "Research and Enterprise",
  "Law",
  "Business and Economics",
];

/**boolean */
export const Discontinue = ["true", "false"];
/**integer */
export const CreditPoints = [6.25, 12.5, 25, 50];

export const InitialSearchQuery: ISubjectQuery = {
  text: "",
  level: [],
  studyPeriod: [],
  areaOfStudy: [],
  courseRecordIds: [],
  onlyBreadth: false,
  onlyDiscipline: true,
  satisfyRequisites: false,
  page: 0,
  pageSize: 50,
  sort: {
    property: "code",
    direction: "ASC",
  },
};
