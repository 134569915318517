import React, { useCallback } from "react";
import logo from "../../assets/images/logo-with-padding.svg";
import { ResponsiveCenter } from "components/spinner/Center";
import styled from "styled-components";
import { AppBar, Button, IconButton, Theme, Toolbar, useTheme } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import "./app-bar.css";
import { Aligned } from "components/aligned/alignedDiv";
import { useSelector } from "react-redux";
import { ComponentType, HintToolTip, IState, UserMode } from "store/types";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { Hint } from "../Hint";
import { useMenuState } from "@szhsin/react-menu";
import MenuIcon from "@mui/icons-material/Menu";
import { featureToggles } from "config/featureToggles";
import { MainMenu } from "./MainMenu";
import user from "./ProfileIcon.svg";

const StyledToolbar = styled(Toolbar)`
  padding-left: 0rem !important;
`;
const Img = styled.img`
  max-height: 5rem;
`;

interface IProps {
  path?: string;
  id?: string;
  openTemplatesDrawer?: () => any;
  mmsRecordIds?: any;
}

const useStyles = (theme: Theme) => ({
  icon: {
    fill: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
});

const AppHeader = (props: IProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const profile = useSelector((s: IState) => s.profile);
  let templates = useSelector((s: IState) => s.enrollment?.matchingTemplates ?? []);
  const { planIsTemplate, userMode } = usePlanPermissions();
  const [menuProps, _toggleMenu] = useMenuState({ transition: true });

  const allowedComponents = useSelector((state: IState) => state?.enrollment?.allowedComponents);
  const components = allowedComponents?.filter((ac) => props.mmsRecordIds?.includes(ac.recordId));
  const streamOrEntryPointComponent = components?.find(
    (component) => component.type === ComponentType.STREAM || component.type === ComponentType.ENTRY,
  );

  if (!!streamOrEntryPointComponent) {
    templates = templates.filter((t: any) => t.mmsRecordIds.includes(streamOrEntryPointComponent.recordId));
  }

  const showTemplates = userMode === UserMode.STUDENT && !planIsTemplate && templates.length > 0;

  // Main Menu actions
  const [mainMenuProps, toggleMainMenu] = useMenuState({ transition: true });
  const openMenu = useCallback(() => toggleMainMenu(true), [toggleMainMenu]);
  const closeMenu = useCallback(() => toggleMainMenu(false), [toggleMainMenu]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onOpenMenu = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      openMenu();
    },
    [setAnchorEl, openMenu],
  );
  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
    closeMenu();
  }, [closeMenu]);

  return (
    <AppBar position="static" className="do-not-print">
      <ResponsiveCenter>
        <Aligned style={{ justifyContent: "space-between" }}>
          <StyledToolbar>
            <Img src={logo} alt="University of Melbourne Logo" />
            <a href={featureToggles.authenticatedUser ? (profile ? "/account" : "/") : "/"} className="page-title">
              My Course Planner {props.id}
            </a>
          </StyledToolbar>

          {showTemplates && !featureToggles.authenticatedUser && (
            <Hint hintType={HintToolTip.SAMPLE_COURSE_PLANS} hideHint={menuProps.state === "open"} placement="left">
              <Button
                variant="outlined"
                className={`do-not-print`}
                sx={styles.button}
                onClick={props.openTemplatesDrawer}
                // TouchRippleProps={{ sx: rippleClasses }}
                startIcon={<DirectionsIcon sx={styles.icon} />}
              >
                Sample Course Plans
              </Button>
            </Hint>
          )}
          {featureToggles.authenticatedUser && (
            <>
              <div style={{ marginLeft: "auto", textAlign: "right", display: "flex", fontSize: "14px" }}>
                {profile && (
                  <div
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      minWidth: "80px",
                      paddingTop: "8px",
                      borderLeft: "0.5px solid #e0e0e0",
                    }}
                  >
                    <img src={user} alt={profile.firstName} width="24px" />
                    <p style={{ marginTop: 4 }}>{profile.firstName}</p>
                  </div>
                )}
                <div
                  style={{
                    textAlign: "center",
                    minWidth: "80px",
                    borderLeft: "0.5px solid #e0e0e0",
                    borderRight: "0.5px solid #e0e0e0",
                  }}
                >
                  {showTemplates ? (
                    <Hint
                      hintType={HintToolTip.SAMPLE_COURSE_PLANS}
                      hideHint={mainMenuProps.state === "open"}
                      placement="left"
                    >
                      <IconButton onClick={onOpenMenu}>
                        <MenuIcon sx={{ fill: "white", fontSize: "24px" }} />
                      </IconButton>
                    </Hint>
                  ) : (
                    <IconButton onClick={onOpenMenu}>
                      <MenuIcon sx={{ fill: "white", fontSize: "24px" }} />
                    </IconButton>
                  )}
                  <p style={{ marginTop: 0 }}>Menu</p>
                </div>
              </div>
              <MainMenu
                anchorEl={anchorEl}
                closeMenu={onCloseMenu}
                showTemplates={showTemplates}
                showTemplatesAction={props.openTemplatesDrawer}
                {...mainMenuProps}
              />
            </>
          )}
        </Aligned>
      </ResponsiveCenter>
    </AppBar>
  );
};

export default AppHeader;
