import React, { useCallback } from "react";
import { Icon, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { resetAllHints } from "actions/enrollment";
import { useDispatch, useSelector } from "react-redux";
import { clearProfile } from "actions/profile";
import { IState } from "store/types";
import { useNavigate } from "@reach/router";
import { ReactComponent as MyCoursePlansIcon } from "./MyCoursePlansIcon.svg";
import { ReactComponent as SampleCoursePlansIcon } from "./SampleCoursePlansIcon.svg";
import { resetAccountHints } from "actions/profile";

interface IProps {
  closeMenu: () => any;
  anchorEl: any;
  showTemplates: boolean;
  showTemplatesAction: () => any;
}

export const MainMenu = ({ closeMenu, showTemplates, showTemplatesAction, anchorEl }: IProps) => {
  const profile = useSelector((state: IState) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    closeMenu();
    dispatch(clearProfile());
    window.location.href = "/apis/v1/logout";
  }, [closeMenu, dispatch]);

  const handleMyCoursePlans = useCallback(async () => {
    closeMenu();
    navigate("/account");
  }, [closeMenu, navigate]);

  const doResetHints = useCallback(
    (_e: React.MouseEvent) => {
      closeMenu();
      if (window.location.href.endsWith("account")) {
        dispatch(resetAccountHints());
      } else {
        dispatch(resetAllHints());
      }
    },
    [closeMenu, dispatch],
  );

  return (
    <Menu
      id="main-menu"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={closeMenu}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "white",
          borderRadius: ".25rem",
          boxShadow: "0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {profile && (
        <MenuItem onClick={handleMyCoursePlans} key="my-course-plans-hints-menu-item">
          <ListItemIcon>
            <Icon component={MyCoursePlansIcon} />
          </ListItemIcon>
          <ListItemText primary="My Course Plans" />
        </MenuItem>
      )}
      {showTemplatesAction && showTemplates && (
        <MenuItem
          onClick={() => {
            closeMenu();
            showTemplatesAction();
          }}
          key="show-templates-hints-menu-item"
        >
          <ListItemIcon>
            <Icon component={SampleCoursePlansIcon} />
          </ListItemIcon>
          <ListItemText primary="Sample course plans" />
        </MenuItem>
      )}
      <MenuItem onClick={doResetHints} key="bring-back-hints-menu-item">
        <ListItemIcon>
          <HelpOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="Display hints" />
      </MenuItem>
      {profile && (
        <MenuItem onClick={handleLogout} key="logout-menu-item">
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      )}
    </Menu>
  );
};
