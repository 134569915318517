import React, { useCallback } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Accordion from "@mui/material/Accordion";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import LaunchIcon from "@mui/icons-material/LaunchOutlined";
import Slide from "@mui/material/Slide";
import Chip from "@mui/material/Chip";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IState, IAvailability, IContact, ISubjectAssessment } from "store/types";
import { useSelector } from "react-redux";
import { CardSubheading, cardStyles } from "./cardStyles";
import uniq from "lodash/uniq";
import styled from "styled-components";
import { NestedRules } from "containers/checklist/NestedRules";
import isArray from "lodash/isArray";
import { commonStyles } from "theme/styles";
import AppTable from "components/app-table/app-table";
import groupBy from "lodash/groupBy";
import { formatDate } from "lib/date";
// import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import { validationGreen } from "theme/colors";
import { SubjectChecklistWarning } from "containers/checklist/SubjectChecklistWarning";
import { useNavigate } from "@reach/router";
import { getSubject, getSubjectImportType } from "lib/subjects";

const useStyles = (theme: Theme) => ({
  appBar: {
    position: "relative",
    paddingRight: "1.5rem",
  },
  appBarHeading: {
    color: theme.palette.common.white,
    fontSize: 25,
    fontWeight: 600,
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    marginBottom: 14,
  },
  toolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContent: {
    background: "#f7f7f7",
    overflowX: "hidden",
  },
  icon: {
    fill: theme.palette.primary.contrastText,
  },
  hurdleLabel: {
    color: theme.palette.warning.main,
    fontWeight: "bold",
  },
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "#f7f7f7",
    },
  },
  accordion: {
    background: "#f7f7f7",
    boxShadow: "none",
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
  },
  label: {
    fontWeight: "bold",
  },
  divider: {
    marginBottom: "27px !important",
    marginTop: "27px !important",
    marginLeft: "0px !important",
    marginRight: "0px !important",
  },
  successIcon: {
    fill: validationGreen.toString(),
  },
});

export const Title = styled.h3`
  font-size: xx-large;
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

const HeadContainer = styled.div`
  padding: 0.1rem 0.1rem 0.1rem 1rem;
  background: #f7f7f7;
`;

const ChipContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  subjectRecordId?: string;
  path?: string;
  close?: () => any;
  addToPlan?: () => any;
  forYear?: number;
}

export function SubjectDetailsModal({ subjectRecordId, close, addToPlan, forYear }: IProps) {
  // What we need from the Redux state
  const { plan, subjects } = useSelector((state: IState) => state.enrollment!);
  const searchSubjects = useSelector((state: IState) => state.subjectSearch?.results || []);
  // const focusInvocationId = useSelector((state: IState) => state.focusInvocationId);
  const planId = plan?.id;
  const subjectIsInPlan = !!(subjects || []).find((s) => s.recordId === subjectRecordId);
  const subject = getSubject(subjects, subjectRecordId) || getSubject(searchSubjects, subjectRecordId);

  // find the subject in the plan slot and determine if its completed
  let subjectImportType = null;
  for (const sp of plan.studyPeriods) {
    const slot = sp.slots.find((sps) => sps.subjectRecordId === subjectRecordId);
    if (slot) {
      subjectImportType = getSubjectImportType(slot);
      break;
    }
  }

  const navigate = useNavigate();
  const closeDefault = useCallback(() => {
    planId && navigate(`../`);
  }, [navigate, planId]);
  const doClose = close ?? closeDefault;

  const theme = useTheme();
  const styles = useStyles(theme);
  const commoncardStyles = cardStyles(theme, {});

  const common = commonStyles(theme);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const allAvailabilities =
    subject && subject.availability
      ? isArray(subject.availability)
        ? subject.availability
        : [subject.availability]
      : [];

  const subjectAvailabilities = allAvailabilities.filter((a) => !forYear || String(a.year) === String(forYear));
  const availabilities = subjectAvailabilities
    ? uniq(subjectAvailabilities.map((a) => a?.studyPeriod)).filter(Boolean)
    : [];
  const availabilityTables = subjectAvailabilities?.map((a: IAvailability) => {
    const contacts = a?.contacts ?? [];
    const coordinator = contacts.find((c: IContact) => c.type.match(/coordinator/i));
    const contactInfo = contacts.find((c: IContact) => c.type === "Contact");
    return {
      studyPeriod: a?.studyPeriod || "N/A",
      contactInfo: contactInfo ? contactInfo.contactDetails : "N/A",
      tableRows: [
        {
          id: `${subject?.id}-${a?.studyPeriod}-coordinator`,
          cell1: "Coordinator",
          cell2: coordinator ? (isArray(coordinator.name) ? coordinator.name[0].full_name : coordinator.name) : "",
        },
        {
          id: `${subject?.id}-${a?.studyPeriod}-mode_of_delivery`,
          cell1: "Mode of delivery",
          cell2: a?.modeOfDelivery || "N/A",
        },
        { id: `${subject?.id}-${a?.studyPeriod}-contact_hrs`, cell1: "Contact hours", cell2: a?.contactHours || "N/A" },
        {
          id: `${subject?.id}-${a?.studyPeriod}-total_time_comm`,
          cell1: "Total time commitment",
          cell2: a?.totalTimeCommittment || "N/A",
        },
        {
          id: `${subject?.id}-${a?.studyPeriod}-teaching_period`,
          cell1: "Teaching period",
          cell2: `${formatDate(a?.periodStartDate) || "N/A"} to ${formatDate(a?.periodEndDate) || "N/A"}`,
        },
        {
          id: `${subject?.id}-${a?.studyPeriod}-last_self_enrol`,
          cell1: "Last self-enrol date",
          cell2: formatDate(a?.lastEnrolDate) || "N/A",
        },
        {
          id: `${subject?.id}-${a?.studyPeriod}-census_date`,
          cell1: "Census date",
          cell2: formatDate(a?.censusDate) || "N/A",
        },
        {
          id: `${subject?.id}-${a?.studyPeriod}-last_withdraw_date`,
          cell1: "Last date to withdraw without fail",
          cell2: formatDate(a?.withdrawWithoutFailDate) || "N/A",
        },
        {
          id: `${subject?.id}-${a?.studyPeriod}-period_ends`,
          cell1: "Assessment period ends",
          cell2: formatDate(a?.assessmentPeriodEndDate) || "N/A",
        },
      ],
    };
  });

  const getHurdleDesc = (hurdleDesc: string) => (
    <React.Fragment>
      <Typography sx={styles.hurdleLabel} variant="body2">
        Hurdle requirement:
      </Typography>
      <Typography variant="body2">{hurdleDesc}</Typography>
    </React.Fragment>
  );

  const getAssessmentDesc = (desc: string, quantity: string | number) => {
    return (
      <React.Fragment>
        <Typography variant="body2">{desc}</Typography>
        {String(quantity).trim() && (
          <ul>
            <li>
              <Typography variant="body2">{quantity}</Typography>
            </li>
          </ul>
        )}
      </React.Fragment>
    );
  };

  const subjectAssessmentByAvailability = groupBy(subject?.subjectAssessment ?? [], "subjectAvailability");
  const assessmentTables = Object.entries(subjectAssessmentByAvailability).map(([key, value]) => ({
    studyPeriod: key,
    tableHeader: { cell1: "Description", cell2: "Timing", cell3: "Percentage" },
    tableRows: (value ?? []).map((a: ISubjectAssessment, i) => ({
      id: `${subject?.id}-${key}-assessment-${i}`,
      cell1: a.hurdle ? getHurdleDesc(a.hurdleRequirementDescription) : getAssessmentDesc(a.description, a.quantity),
      cell2: a.timing.toString(),
      cell3: a.percentage ? `${a.percentage.toString()}%` : "",
    })),
  }));
  const displayAssessmentTable = subjectIsInPlan && subject?.subjectAssessment && subject?.subjectAssessment.length > 0;
  const displayAssessmentText = subject?.assessment;
  const validation = useSelector((s: IState) => s.enrollment?.validation);
  const ruleInvocations = (validation?.invocations || []).filter((i) => i.recordId === subject?.recordId);

  if (!planId || !subject) {
    return null;
  }

  const hasRules = ruleInvocations.length > 0;

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      open={true}
      onClose={doClose}
      TransitionComponent={Transition}
      // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
      TransitionProps={{ role: "presentation" } as any}
      PaperProps={{ style: { overflowX: "hidden" } }}
    >
      <AppBar sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <Typography variant="h1" sx={styles.appBarHeading}>
            Subject Details
          </Typography>
          <Button color="inherit" onClick={doClose} aria-label="Close">
            <CloseIcon sx={styles.icon} />
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent sx={styles.dialogContent}>
        <CardSubheading>
          <strong>{subject.code}</strong> | Level {subject.level} | {subject.points} points
        </CardSubheading>

        <Typography variant="h2" sx={styles.title}>
          {subject.name}
        </Typography>

        <ChipContainer>
          <div>
            {availabilities.map((a) => (
              <React.Fragment key={a}>
                <Chip sx={commoncardStyles.cardChip} label={a} size="small" /> &nbsp;
              </React.Fragment>
            ))}
            {subject.hasQuota && <Chip label="Quotas apply" size="small" sx={common.warningChip} />}
          </div>

          <div>
            {subjectImportType !== null && (
              <>
                <Chip
                  label={subjectImportType}
                  sx={{
                    backgroundColor: subjectImportType === "Enrolled" ? "#4074B2" : "#1C407F",
                    color: "white",
                  }}
                  icon={<CheckCircleIcon sx={styles.successIcon} />}
                />
                &nbsp;&nbsp;
              </>
            )}
            {subjectIsInPlan && (
              <Chip label="In plan" color="primary" icon={<CheckCircleIcon sx={styles.successIcon} />} />
            )}
          </div>
          {!subjectIsInPlan && addToPlan && (
            <Button variant="outlined" color="primary" endIcon={<AddIcon />} onClick={addToPlan}>
              Add to plan
            </Button>
          )}
        </ChipContainer>

        <Grid container={true} spacing={3} style={{ overflowX: "hidden" }}>
          <Grid item={true} xs={12} sm={hasRules ? 7 : 10} md={hasRules ? 8 : 12}>
            <div dangerouslySetInnerHTML={{ __html: subject.overview }} />
            <a
              style={common.link}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://handbook.unimelb.edu.au/subjects/${subject.code}`}
            >
              <span style={{ display: "inherit" }}>
                View more details in Handbook <br />
                <LaunchIcon sx={common.linkIcon} />
              </span>
            </a>
            <Divider variant="middle" sx={styles.divider} />
            {availabilities.length > 0 && (
              <div>
                <Typography variant="h6" variantMapping={{ h6: "h3" }}>
                  Date, times & subject coordinator contact details
                </Typography>
                {availabilityTables?.map((a: any, i: number) => (
                  <Accordion
                    key={`${subject.code}-${a.studyPeriod}-availability-${i}`}
                    sx={styles.accordion}
                    // classes={{ root: styles.accordionRoot }}
                  >
                    <AccordionSummary
                      sx={styles.accordionSummary}
                      expandIcon={<ExpandMore />}
                      aria-controls={`${a.studyPeriod}-availability-content`}
                      id={`${a.studyPeriod}-availability-header`}
                    >
                      <Typography variant="subtitle1" variantMapping={{ subtitle1: "h4" }} sx={styles.label}>
                        {a.studyPeriod}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={common.column}>
                      <AppTable tableRows={a.tableRows} />
                      <Typography variant="subtitle1">{a.studyPeriod} contact information</Typography>
                      <div dangerouslySetInnerHTML={{ __html: a.contactInfo }} />
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Divider variant="middle" sx={styles.divider} />
              </div>
            )}
            {(displayAssessmentTable || displayAssessmentText) && (
              <Typography variant="h6" variantMapping={{ h6: "h3" }}>
                Assessment
              </Typography>
            )}
            {displayAssessmentTable ? (
              assessmentTables.map((at: any, i) => (
                <div key={`${subject.code}-${at.studyPeriod}-assessment-${i}`}>
                  <Typography variant="subtitle1" variantMapping={{ subtitle1: "h4" }} sx={styles.label}>
                    {at.studyPeriod}
                  </Typography>
                  <AppTable tableRows={at.tableRows} tableHeader={at.tableHeader} outlined={true} />
                </div>
              ))
            ) : displayAssessmentText ? (
              <div dangerouslySetInnerHTML={{ __html: subject.assessment }} />
            ) : null}
          </Grid>
          {hasRules && (
            <Grid item={true} xs={12} sm={5} md={4}>
              <Paper elevation={0}>
                <HeadContainer>
                  <Typography variant="h6" variantMapping={{ h6: "h3" }}>
                    Subject Rules
                  </Typography>
                </HeadContainer>
                {plan && (
                  <NestedRules invocations={ruleInvocations} expandedByDefault={true} onHighlightOpen={doClose} />
                )}
                <SubjectChecklistWarning subjectRecordId={subject?.recordId} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
